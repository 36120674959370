<div class="articleItemContainer">
    <div class="firstColumn">
        <img class="image" [src]="image"/>
    </div>
    <div class="secondColumn">
        <div class="content">
            {{name}}
        </div>
        <div class="buttonContainer">
            <app-button [name]="'Zum Artikel'" [routerLink]="['/',route,id]"></app-button>
        </div>
    </div>
</div>
