<div class="glideContainer" [ngClass]="location">
  <div class="glide__track glideTracker" data-glide-el="track">
    <div class="glide__slides glideSlides">
      <div
        class="glide__slide slideItem"
        *ngFor="let item of products; index as i"
        [id]="item.id + '-' + location"
        [routerLink]="['/productDetail/' + item.id]"
      >
        <img
          class="slideImage"
          [ngStyle]="{
            filter: item?.soldOut == true ? 'grayscale(0.9)' : 'grayscale(0)'
          }"
          [src]="item?.sizes[0].image"
          onerror="this.src='../../../assets/imgs/alt_Picture.png'"
        />
      </div>
    </div>
  </div>

  <div class="glideArrows" data-glide-el="controls">
    <div class="glideArrow arrowLeft" data-glide-dir="<">
      <img class="arrowIconleft" src="../../../assets/imgs/pfeil_unten.svg" />
    </div>
    <div class="glideArrow arrowRight" data-glide-dir=">">
      <img class="arrowIconRight" src="../../../assets/imgs/pfeil_unten.svg" />
    </div>
  </div>
</div>

<div class="productDescriptionContainer">
  <div class="productName">
    {{ currentItem?.name
    }}{{ currentItem?.soldOut == true ? " (Ausverkauft)" : "" }}
  </div>
</div>

<!--
<div class="progessBarCenter">
  <div class="progressBar">
    <div class="progressBarActive"></div>
  </div>
</div>


<div class="progessCategoriesCenter">
  <div class="progressCategories">
    <div class="itemLimo">
      Limo
    </div>
    <div class="itemSaft">
      Säfte
    </div>
    <div class="itemOther">
      Eistee
    </div>
    <div class="itemOther">
      Wasser
    </div>
  </div>
</div>
-->
