<div class="aboutContentCenter">
  <div class="aboutContent">
    <h1 class="header">IMPRESSUM · DATENSCHUTZ</h1>
    <div class="aboutItem">
      <h2 class="itemHeader">Verantwortlichkeit:</h2>
      <div class="content">
        Die VO ÜS Vorarlberger Limo Werk GmbH zeichnet sich für den Inhalt
        dieser Website verantwortlich.<br />
        <br />
        VO ÜS Vorarlberger Limo Werk GmbH<br />
        Millennium Park 10<br />
        6890 Lustenau<br />
        Vorarlberg/Austria<br />
        <br />
        T +43 (0)5572 3777 555<br />
        <a href="mailto:info@voüs.at">info@voüs.at</a><br />
        <br />
        Firmenbuchnummer: FN 525938 p<br />
        Firmenbuchgericht: LG Feldkirch<br />
        UID-Nummer: ATU75174801
      </div>
    </div>

    <div class="aboutItem">
      <h2 class="itemHeader">Haftungsausschluss</h2>
      <div class="content">
        Der Inhalt dieser Website ist urheberrechtlich geschützt. Sämtliche
        Inhalte, Texte, Grafiken und Fotos sind Eigentum der VO ÜS Vorarlberger
        Limo Werk GmbH, alle Rechte vorbehalten. Bestimmte Inhalte können des
        Weiteren auch den Urheberrechts- und Nutzungsbedingungen von Partnern
        oder Lieferanten unterliegen. Die Bereitstellung von Inhalten und
        Bildmaterial dieser Website auf anderen Websites ist nur mit
        ausdrücklicher Genehmigung der VO ÜS Vorarlberger Limo Werk GmbH
        gestattet. Die auf dieser Website enthaltenen Angaben werden nach bestem
        Wissen erstellt und mit großer Sorgfalt auf ihre Richtigkeit überprüft.
        Trotzdem sind inhaltliche und sachliche Fehler nicht vollständig
        auszuschließen. Die VO ÜS Vorarlberger Limo Werk GmbH übernimmt
        keinerlei Garantie und Haftung für die Richtigkeit, Aktualität und
        Vollständigkeit der bereitgestellten Informationen. Alle Angaben sind
        ohne Gewähr. Dies gilt auch für alle Links zu anderen URLs, die auf
        dieser Website genannt werden.<br />

        <br /><strong>Haftung für Links</strong><br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernimmt die VO ÜS
        Vorarlberger Limo Werk GmbH keine Haftung für die Inhalte externer
        Links. Die VO ÜS Vorarlberger Limo Werk GmbH übernimmt ferner keine
        Verantwortung für Inhalte auf Websites von deren Partnern und Kunden,
        auf die mittels Links verwiesen wird. Für den Inhalt der verlinkten
        Seiten sind ausschließlich deren Betreiber verantwortlich.<br />

        <br /><strong>Datenschutzerklärung für die Nutzung von Google Analytics</strong><br />
        Diese Website benutzt Google Analytics. Dies sind Dienstleistungen der
        Google Inc. (“Google”). Google verwendet sog. “Cookies”, Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch sie ermöglicht. Die durch den Cookie
        erzeugten Informationen über Ihre Benutzung diese Website
        (einschliesslich Ihrer IP-Adresse) wird an einen Server von Google in
        den USA übertragen und dort gespeichert. Die IP Adresse wird
        anschliessend von Google um die letzten drei Stellen gekürzt, eine
        eindeutige Zuordnung der IP Adresse ist somit nicht mehr möglich. Google
        beachtet die Datenschutzbestimmungen des “US-Safe-Harbor”-Abkommens und
        ist beim “Safe Harbor”-Programm des US-Handelsministeriums registriert.
        Google wird diese Informationen benutzen, um Ihre Nutzung der Website
        auszuwerten, um Reports über die Websiteaktivitäten für die
        Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
        wird Google diese Informationen gegebenenfalls an Dritte übertragen,
        sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
        Auftrag von Google verarbeiten.<br />
        <br />Drittanbieter, einschliesslich Google, schalten Anzeigen auf
        Websites im Internet. Drittanbieter, einschliesslich Google, verwenden
        gespeicherte Cookies zum Schalten von Anzeigen auf Grundlage vorheriger
        Besuche eines Nutzers auf dieser Website.<br />
        Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google
        in Verbindung bringen. Der Datenerhebung und -speicherung kann jederzeit
        mit Wirkung für die Zukunft widersprochen werden. Sie können die
        Verwendung von Cookies durch Google durch eine entsprechende Einstellung
        Ihrer Browser Software deaktivieren, indem sie die Seite
        www.google.de/privacy/ads aufrufen und den „opt-out“ Button betätigen.
        Alternativ können Sie die Verwendung von Cookies durch Drittanbieter
        deaktivieren, indem Sie die Deaktivierungsseite der
        Netzwerkwerbeinitiative unter
        www.networkadvertising.org/managing/opt_out.asp aufrufen. Weitere
        Hinweise zu den Bestimmungen von Google finden Sie unter
        www.google.com/intl/de/privacy.
        <br />Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website voll umfänglich
        nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit
        der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor
        beschriebenen Art und Weise und zu dem zuvor benannten Zweck
        einverstanden. <br />Datenschutzerklärung für die Nutzung von
        Facebook-Plugins (Like-Button) <br />Auf unseren Seiten sind Plugins des
        sozialen Netzwerks Facebook, 1601 South California Avenue, Palo Alto, CA
        94304, USA integriert. Die Facebook-Plugins erkennen Sie an dem
        Facebook-Logo oder dem “Like-Button” (“Gefällt mir”) auf unserer Seite.
        Eine Übersicht über die Facebook-Plugins finden Sie hier:
        http://developers.facebook.com/docs/plugins/. Wenn Sie unsere Seiten
        besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem
        Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die
        Information, dass Sie mit Ihrer IP-Adresse unsere Seite besucht haben.
        Wenn Sie den Facebook “Like-Button” anklicken während Sie in Ihrem
        Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten
        auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
        unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
        wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
        Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen
        hierzu finden Sie in der Datenschutzerklärung von facebook unter
        https://www.facebook.com/about/privacy/ . Wenn Sie nicht wünschen, dass
        Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen
        kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
      </div>
    </div>
  </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
