import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import Glide from '@glidejs/glide'
import { gsap, TimelineLite, Power2 } from 'gsap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'product-slider',
  templateUrl: './product-slider.component.html',
  styleUrls: ['./product-slider.component.scss']
})
export class ProductSliderComponent implements OnInit {

  @Output() change = new EventEmitter();
  @Input() autoplay: any;
  @Input() location: any;

  activeIndex: any = null;

  products: any;

  glide: any;
  currentItem: any;
  prevItem: any = null;
  active: any;

  screenWidth: any = 0;

  enterFirstTime: boolean = true;

  tl = new TimelineLite();

  itemHeight: any = "430px";
  activeItemHeight: any = "530px"

  constructor(private dataService: DataService) 
  {
    this.products = this.dataService.getProducts();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.glide.destroy();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

    }
    else{
     setTimeout(() => {
       window.location.reload();
     }, 1000);
    }
  }

  ngAfterViewInit(){

    setTimeout(() => {
      this.glide = new Glide('.' + this.location, {
        type: 'carousel',
        focusAt: 4,
        perView: 7,
        startAt: 8,
        autoplay: this.autoplay,
        perTouch: 1,
        breakpoints: {
          576: {
            perView: 3,
            focusAt: 2,
          },
          1100: {
            perView: 5,
            focusAt: 3
          }
        }
       });
  
       var self = this;
       var slices;
       var itemIsActive;
   
       this.glide.on(['run.before'], (event) => {
          var allItemsActive = document.querySelectorAll('.glide__slide--active');
          allItemsActive.forEach(element => {
            if(element.id.includes(self.location)){
              itemIsActive = element;
            }
           });
    
          if (event.direction == ">" && !self.enterFirstTime) {
            if (self.glide.index == 0) {
              self.active = document.getElementById(self.products[self.products.length - 1].id + "-" + self.location).nextElementSibling.children[0];
              self.activeIndex = self.products[0].id
            }
            else {
              //self.active = document.querySelector('.glide__slide--active').children[0];
              self.active = itemIsActive.children[0];
              slices = itemIsActive.id.split("-");
              self.activeIndex = slices[0];
            }
          }
          else if(event.direction == "<" && !self.enterFirstTime) {
            if(self.glide.index == 0){
              var array = document.querySelectorAll("[id='"+ self.products[self.products.length - 2].id + "-" + self.location + "']");
              self.active = array[1].children[0];
              slices = array[1].id.split("-");
              self.activeIndex = slices[0];
            }
            else{
              self.active = itemIsActive.previousElementSibling.previousElementSibling.children[0];
              slices = itemIsActive.previousElementSibling.previousElementSibling.id.split("-");
              self.activeIndex = slices[0];
            }
          }
    
          //enter first time
          if (self.enterFirstTime) {
            self.active = itemIsActive.previousElementSibling.children[0];
            slices = itemIsActive.id.split("-");
            self.activeIndex = slices[0];
            self.enterFirstTime = false;
          }

          //last item
          if(self.glide.index == self.products.length - 1){
            if(event.direction == ">"){
              self.active = document.getElementById(self.products[self.products.length - 1].id + "-" + self.location).children[0];
              self.activeIndex = self.products[self.products.length - 1].id;
            }
            else{
              self.active = itemIsActive.previousElementSibling.previousElementSibling.children[0];
              slices = itemIsActive.previousElementSibling.previousElementSibling.id.split("-");
              self.activeIndex = slices[0];
            }
          }
 
          this.tl.progress(0).clear();
    
          if (self.prevItem != null) {
            self.tl.to(self.prevItem, 1, { height: '70%', ease: Power2.easeOut})
              .to(self.active, 1, { height: '90%', ease: Power2.easeOut}, 0.5);
          }
          else {
            self.tl.to(self.active, 0.5, { height: '90%', ease: Power2.easeOut });
          }
          self.currentItem = self.dataService.getProduct(self.activeIndex);
          self.change.emit(self.activeIndex);

          setTimeout(() => {
            self.prevItem = self.active;
          }, 1000);
 
          //progressbar
          //var progress = (self.glide.index * 100) / self.products.length - 1;
          //var progressBar = document.querySelector('.progressBarActive');
          //gsap.to( progressBar, 0.5, { left: progress + '%', ease: Power2.easeOut });
       });
   
       this.glide.mount();

       setTimeout(() => {
        var activeItem = document.querySelector('.glide__slide--active').previousElementSibling.children[0];
        var allItemsActive = document.querySelectorAll('.glide__slide--active');
        allItemsActive.forEach(element => {
          if(element.id.includes(self.location)){
            itemIsActive = element;
          }
         });
        var slice = itemIsActive.previousElementSibling.id.split("-");
        this.activeIndex = slice[0];
        this.currentItem = this.dataService.getProduct(self.activeIndex);
        this.tl.to(activeItem, 0.5, { height: '90%', ease: Power2.easeOut });
        self.prevItem = activeItem;
       }, 100);
    }, 2);
  }
}
