<div class="aboutContentCenter">
  <div class="aboutContent">
    <h1 class="header">Teilnahmebedingungen</h1>

    <div class="aboutItem">
      <div class="content">
        <strong>1. Gewinn</strong>
        <br />
        Der zu gewinnende Preis ist wie im jeweiligen Post beschrieben.
        <br />
        <br />
        <strong>2. Dauer</strong>
        <br />
        Die Dauer des Gewinnspiels ist wie im jeweiligen Post beschrieben.
        <br />
        <br />
        <strong>3. Bekanntgabe der Gewinner</strong>
        <br />
        Nach Ablauf des Zeitraums werden die Gewinner vom Betreiber ermittelt
        und benachrichtigt. Die Gewinne werden nur unter den Teilnehmern
        verlost.
        <br />
        <br />
        <strong>4. Teilnahmeberechtigung</strong>
        <br />
        Teilnahmeberechtigt sind natürliche Personen, die Ihren Wohnsitz in
        Vorarlberg und das 14. Lebensjahr vollendet haben. Nicht
        teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und
        Umsetzung des Gewinnspiels beteiligten Personen und Mitarbeiter der VO
        ÜS Vorarlberger Limo Werk GmbH. Zudem behält sich der Betreiber vor,
        nach eigenem Ermessen Personen von der Teilnahme auszuschließen, wenn
        berechtigte Gründe vorliegen.
        <br />
        <br />
        <strong>5. Verlosung</strong>
        <br />
        Die Auslosung findet unter Ausschluss der Öffentlichkeit statt. Es wird
        mittels Zufallsprinzip ausgelost.
        <br />
        <br />
        <strong>6. Facebook Disclaimer</strong>
        <br />
        Die Promotion steht in keiner Verbindung zu Facebook bzw. Instagram und
        wird in keiner Weise von Facebook gesponsert, unterstützt oder
        organisiert.
        <br />
        <br />
        <strong>7. Weitere Bedingungen</strong>
        <br />
        1) Die VO ÜS Vorarlberger Limo Werk GmbH behält sich das Recht vor, die
        Verlosung zu jedem Zeitpunkt ohne Angabe von Gründen abzubrechen oder zu
        beenden. Von dieser Möglichkeit wird insbesondere Gebrauch gemacht, wenn
        eine ordentliche Durchführung der Verlosung aus technischen Gründen oder
        aus rechtlichen Gründen nicht gewährleistet werden kann. Falls der
        Wettbewerb vorzeitig abgebrochen wird, können aus der vorzeitigen
        Beendigung keine Ansprüche abgeleitet werden.
        <br />
        2) Verstößt ein Teilnehmer gegen die Regeln kann er ausgeschlossen
        werden. Dieses Recht wird vor allem wahrgenommen, wenn diese Person sich
        unerlaubten Hilfsmitteln bedient, oder sich durch Manipulation Vorteile
        in jeglicher Art verschafft. In diesem Fall können Gewinne nachträglich
        aberkannt bzw. zurückgefordert werden.
        <br />
        3) Doppelte Gewinne werden ausgeschlossen!
        <br />
        4) Der Rechtsweg ist ausgeschlossen.
        <br />
        5) Änderungen bleiben vorbehalten.
        <br /><br />
        Gewinnspiel-Veranstalter ist die VO ÜS Vorarlberger Limo Werk GmbH
        <br />
        Bei Fragen steht das VO ÜS Team gerne zur Verfügung.
      </div>
    </div>
  </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
