<div class="headerContainer">
    <img *ngIf="type == 'mittagsspitze'" class="headerImage" src="../../../assets/imgs/mountains/damülser_mittagsspitze.jpg"/>
    <img *ngIf="type == 'drei_schwestern'" class="headerImage" src="../../../assets/imgs/mountains/drei_schwestern.jpg"/>
    <img *ngIf="type == 'roggalspitze'" class="headerImage" src="../../../assets/imgs/mountains/roggalspitze.jpg"/>
    <img *ngIf="type == 'üntschenspitze'" class="headerImage" src="../../../assets/imgs/mountains/üntschenspitze.jpg"/>
    <img *ngIf="type == 'vallüla'" class="headerImage" src="../../../assets/imgs/mountains/vallüla.jpg"/>
    <img *ngIf="type == 'zimba'" class="headerImage" src="../../../assets/imgs/mountains/zimba.jpg"/>
    <img *ngIf="type == 'newsHeader'" class="headerImage birdResponsive" src="../../../assets/imgs/newsHeader.png"/>
    <img *ngIf="type == 'headerGemacht'" class="headerImage" src="../../../assets/imgs/headerGemacht.png"/>
    <img *ngIf="type == 'insel'" class="headerImage inselResponsive" src="../../../assets/imgs/insel.jpg"/>
    <div class="headerNameContainer">
        <h1 class="headerName">{{name}}</h1>
    </div>
</div>
