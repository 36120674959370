<div class="pressContainer">
    <app-header class="header" [name]="'PRESSE'" [type]="'üntschenspitze'"></app-header>

    <div class="pressInstructionContainer">
        <div class="introText">Was sollen wir vo üs erzählen?</div>
        <div class="introContent">Vo üs gibt es immer wieder jede Menge Neues.</div>
        <div class="buttonsContainer">
            <!--<app-button [name]="'Pressekontakt'" class="buttonItem"></app-button>-->
            <app-button (click)="goTopressKit()" [name]="'Presse-Kit'" class="buttonItem"></app-button>
            <app-button (click)="goToDownloads()" [name]="'Downloads'" class="buttonItem"></app-button>
        </div>
    </div>

    <div class="pressItemsContainer">
        <div class="articleContainer" *ngFor="let item of pressData">
            <article-item [name]="item.name" [image]="item.image" [route]="'newsArticle'" [id]="item.id"></article-item>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
