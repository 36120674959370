import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Title, Meta } from '@angular/platform-browser';
import { gsap } from 'gsap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {

  pressData: any = [];

  constructor(private dataService: DataService, private title: Title, private meta: Meta, private router: Router) 
  { 
    //this.pressData = this.dataService.getPressArticles();
    this.getNews();
  }

  getNews = async () => {
    this.pressData = await this.dataService.getAllNews();
  }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Presse");
    this.meta.addTag({ name: 'description', content: 'Presseinfos, Fotos, Downloads: Herzlich willkommen bei VO ÜS. '});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Presse' });
    this.meta.addTag({ name: 'og:description', content: 'Presseinfos, Fotos, Downloads: Herzlich willkommen bei VO ÜS.'});
    window.scroll(0,0);
  }

  ngAfterViewInit(){
    gsap.from(".articleContainer", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
}

goToDownloads(){
  this.router.navigateByUrl('/downloads');
}

goTopressKit(){
  this.router.navigateByUrl('/pressKit');
}

}
