import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DraggableSliderComponent } from './components/draggable-slider/draggable-slider.component';
import { NewsComponent } from './pages/news/news.component';
import { ProductSliderComponent } from './components/product-slider/product-slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { StoryComponent } from './pages/story/story.component';
import { ArticleItemComponent } from './components/article-item/article-item.component';
import { ButtonComponent } from './components/button/button.component';
import { PressComponent } from './pages/press/press.component';
import { PressArticleComponent } from './pages/press-article/press-article.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { AgmCoreModule } from '@agm/core';
import { CreatedFromComponent } from './pages/created-from/created-from.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ProductsOfCategoryComponent } from './components/products-of-category/products-of-category.component';
import { ProductCategorySliderComponent } from './components/product-category-slider/product-category-slider.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OutlineButtonComponent } from './components/outline-button/outline-button.component';
import { AboutComponent } from './pages/about/about.component';
import { HeaderComponent } from './components/header/header.component';
import { AgbComponent } from './pages/agb/agb.component';
import { environment } from 'src/environments/environment';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { PressKitComponent } from './pages/press-kit/press-kit.component';
import { LiablityCommitteeComponent } from './pages/liablity-committee/liablity-committee.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    DraggableSliderComponent,
    NewsComponent,
    ProductSliderComponent,
    FooterComponent,
    ProductDetailComponent,
    StoryComponent,
    ArticleItemComponent,
    ButtonComponent,
    PressComponent,
    PressArticleComponent,
    LocationsComponent,
    CreatedFromComponent,
    FaqComponent,
    ProductsOfCategoryComponent,
    ProductCategorySliderComponent,
    ContactComponent,
    OutlineButtonComponent,
    AboutComponent,
    HeaderComponent,
    AgbComponent,
    DownloadsComponent,
    PressKitComponent,
    LiablityCommitteeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDj1q3iLFo2DeMcoTjkwru6NBfHQvnfJUs'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [FormBuilder],
  bootstrap: [AppComponent]
})
export class AppModule { }
