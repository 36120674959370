import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { TimelineLite } from 'gsap';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  lat: number = 47.412490;
  lng: number = 9.744620;
  zoom: number = 9;

  locations: any = [];

  openedWindow : number = 0; // alternative: array of numbers

  constructor(private title: Title, private meta: Meta, private dataService: DataService) { }

  ngOnInit(): void {
    this.title.setTitle("Hier gibt’s VO ÜS » Der Produktfinder");
    this.meta.addTag({ name: 'description', content: 'Kreuz und quer und überall in Vorarlberg: Hier bekommst du VO ÜS aus dem Regal, am Tisch oder am Tresen.'});
    this.meta.addTag({ name: 'og:title', content: 'Hier gibt’s VO ÜS » Der Produktfinder' });
    this.meta.addTag({ name: 'og:description', content: 'Kreuz und quer und überall in Vorarlberg: Hier bekommst du VO ÜS aus dem Regal, am Tisch oder am Tresen.'});
    this.locations = this.dataService.getLocations();
    this.openWindow(this.locations[0].id);
    window.scroll(0,0);
  }

  ngAfterViewInit(){
    var tl = new TimelineLite();
    tl.from(".descriptionContainer", 0.2, { x: -20, opacity: 0})
      .from("mapsContainer", 0.2, {x: -20, opacity: 0}, 0.1);
  }

  openWindow(id) {
    this.openedWindow = id; // alternative: push to array of numbers
}

  isInfoWindowOpen(id) {
      return this.openedWindow == id; // alternative: check if id is in array
  }

}
