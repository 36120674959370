// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Your web app's Firebase configuration
  firebaseConfig: {
    apiKey: "AIzaSyDj1q3iLFo2DeMcoTjkwru6NBfHQvnfJUs",
    authDomain: "voues-270518.firebaseapp.com",
    databaseURL: "https://voues-270518.firebaseio.com",
    projectId: "voues-270518",
    storageBucket: "voues-270518.appspot.com",
    messagingSenderId: "887288104772",
    appId: "1:887288104772:web:accceae0e68890fe1b7911",
    measurementId: "G-X01X1C5DP9"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
