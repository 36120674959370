<div class="productDetailContainer">
  <div class="productDetailContent">
    <div class="productPreviewContainter">
      <img
        *ngIf="!isMobile || activeCategories?.length == 1"
        [ngStyle]="{
          filter: product?.soldOut == true ? 'grayscale(0.9)' : 'grayscale(0)'
        }"
        class="productImage"
        [src]="activeImage"
      />
      <product-category-slider
        *ngIf="isMobile"
        [style.display]="activeCategories?.length == 1 ? 'none' : 'flex'"
        class="productCategorySlider"
        [setProductId]="product.id"
        [setCategory]="activeCategory"
        (change)="changeItemMobile($event)"
      >
      </product-category-slider>
    </div>
    <div class="productDescriptionContainer">
      <div class="soldout-row">
        <div class="soldout-label primary" *ngIf="product?.soldOut == true">
          Limitiert
        </div>
        <div class="soldout-label" *ngIf="product?.soldOut == true">
          Ausverkauft
        </div>
      </div>
      <div class="productName">
        {{ product?.name }}
      </div>
      <div
        class="productDescription"
        [class.productDescriptionMobile]="isMobile"
        (click)="showMore($event)"
      >
        {{ product?.description }}
      </div>
      <div class="drinkAsContainer">
        <div class="productDetailInstruction">Trinkst du als:</div>
        <div
          class="productDetailsContainer"
          [class.sizesRow]="product?.sizes.length <= 1"
        >
          <div class="productSizes">
            <div *ngFor="let item of product?.sizes; let i = index">
              <outline-button
                *ngIf="product.id != 12 || (product.id == 12 && i == 0)"
                (click)="changePreviewImage(item.image)"
                [name]="item.size + '  Flasche'"
              ></outline-button>
              <!-- <outline-button
                *ngIf="product.id == 12 && i == 1"
                (click)="changePreviewImage(item.image)"
                [name]="item.size + '  PET-Flasche'"
              ></outline-button> -->
            </div>
          </div>
          <!-- <div class="productSizes" *ngIf="product?.id == 12">
                        <outline-button (click)="changePreviewImage(item.image)" *ngFor="let item of product?.sizes"
                            [name]="item.size + '  PET-Flasche'"></outline-button>
                    </div> -->
          <app-button
            [name]="'Zutaten & Nährwerte'"
            (click)="toogleIngredientsAnimation()"
          ></app-button>
        </div>
      </div>
      <div class="productsOfCategoryContainer" *ngIf="!isMobile">
        <products-of-category
          *ngIf="activeCategories?.length > 1"
          [currentProduct]="product"
          [setCategory]="activeCategory"
          (productChanged)="productChanged($event)"
        ></products-of-category>
      </div>
    </div>
  </div>

  <div class="ingredientsContainer">
    <div class="closeContainer">
      <img
        class="closeIcon"
        src="../../../assets/imgs/navi_x.svg"
        (click)="toogleIngredientsAnimation()"
      />
    </div>
    <div class="contentContainer">
      <div class="ingredients">
        <div class="ingredientsHeader">Zutaten</div>
        <div class="ingredientsContent">
          {{ product?.ingredients }}
        </div>
      </div>
      <div class="nutritionalValues">
        <div class="nutritionalHeader">Nährwerte pro 100ml</div>
        <div class="nutritionalDetails">
          <div class="nutritionalDescription">
            <div
              class="descItem"
              *ngFor="let item of product?.nutritionalValues"
            >
              {{ item?.name }}
            </div>
          </div>
          <div class="nutritionalValues">
            <div
              class="valItem"
              *ngFor="let item of product?.nutritionalValues"
            >
              {{ item?.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footerContainer">
      <img class="logo" src="../../../assets/imgs/navi_logo_weiss.svg" />
    </div>
  </div>
</div>

<div class="productCategoriesContainer">
  <div class="productCategories">
    <div class="categoryItem" (click)="changeCategory('Limo')">
      <img
        *ngIf="activeCategory == 'Limo'; else notLimo"
        class="productImageCat"
        src="../../../assets/imgs/produkt_navi_limo_blau.svg"
      />
      <ng-template #notLimo>
        <img
          class="productImageCat"
          src="../../../assets/imgs/produkt_navi_limo_hellblau.svg"
        />
      </ng-template>
      <div>Limo</div>
    </div>
    <div class="categoryItem" (click)="changeCategory('Saft')">
      <img
        *ngIf="activeCategory == 'Saft'; else notSaft"
        class="productImageCat"
        src="../../../assets/imgs/produkt_navi_saft_blau.svg"
      />
      <ng-template #notSaft>
        <img
          class="productImageCat"
          src="../../../assets/imgs/produkt_navi_saft_hellblau.svg"
        />
      </ng-template>
      <div>Saft</div>
    </div>
    <div class="categoryItem" (click)="changeCategory('Eistee')">
      <img
        *ngIf="activeCategory == 'Eistee'; else notEistee"
        class="productImageCat"
        src="../../../assets/imgs/produkt_navi_eistee_blau.svg"
      />
      <ng-template #notEistee>
        <img
          class="productImageCat"
          src="../../../assets/imgs/produkt_navi_eistee_hellblau.svg"
        />
      </ng-template>
      <div>Eistee</div>
    </div>
    <div class="categoryItem" (click)="changeCategory('Wasser')">
      <img
        *ngIf="activeCategory == 'Wasser'; else notWasser"
        class="productImageCat"
        src="../../../assets/imgs/produkt_navi_wasser_blau.svg"
      />
      <ng-template #notWasser>
        <img
          class="productImageCat"
          src="../../../assets/imgs/produkt_navi_wasser_hellblau.svg"
        />
      </ng-template>
      <div>Wasser</div>
    </div>
  </div>
</div>

<!-- PRODUCT SLIDER -->
<!--
<div class="productSliderContainer" *ngIf="!isMobile">
    <div class="productSlider">
        <product-slider [autoplay]="false" [location]="'productOfSliderDetail'" (change)="openProduct($event)"></product-slider>
    </div>
</div>
-->

<!--FOOTER-->
<app-footer></app-footer>
