import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import Glide from '@glidejs/glide'
import { gsap, TimelineLite, Power2 } from 'gsap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'product-category-slider',
  templateUrl: './product-category-slider.component.html',
  styleUrls: ['./product-category-slider.component.scss']
})
export class ProductCategorySliderComponent implements OnInit {

  @Output() change = new EventEmitter();
  @Input() set setCategory(val: any) {
    if(this.calledInputCategoryFirstTime){
      this.activeCategory = val;
      this.products = this.dataService.getProductsOfCategory(val);
      this.calledInputCategoryFirstTime = false;
    }
    else{
      this.enterFirstTime = true;
      this.prevItem = null;
      this.activeCategory = val;
      this.products = this.dataService.getProductsOfCategory(val);
      this.glider.destroy();
      setTimeout(() => {
        this.buildGlide();
      }, 100);
    }
  }

  @Input() set setProductId(val: any){
    this.activeProductId = val;
    if(this.enterProductIdFirstTime){
      var startId = 0;
      setTimeout(() => {
        for(var i = 0; i < this.products.length; i++){
          if(this.products[i].id == val){
            startId = i;
          }
        }

        this.glider.update({
          startAt: startId + 1
        })
      }, 150);
      
      /*
      setTimeout(() => {
        this.glider.update({
          startAt: val - 1
        })
      }, 150);
      */
      this.enterProductIdFirstTime = false;
    }
  }

  activeProductId;

  activeCategory: any = "Limo";

  activeIndex: any = null;

  products: any = [];

  glider: any;
  currentItem: any;
  prevItem: any = null;
  active: any;

  screenWidth: any = 0;

  focusItem: any = 0;

  enterFirstTime: boolean = true;
  enterProductIdFirstTime: boolean = true;
  calledInputCategoryFirstTime: boolean = true;

  tl = new TimelineLite();

  constructor(private dataService: DataService) {
    //this.products = this.dataService.getProductsOfCategory(this.activeCategory);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.glider.destroy();
  }

  ngAfterViewInit() {
    this.buildGlide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {

    }
    else{
     setTimeout(() => {
       window.location.reload();
     }, 1000);
    }
  }

  buildGlide(){
    this.glider = new Glide('.glider', {
      type: 'carousel',
      focusAt: 2,
      perView: 3,
      perTouch: 1,
      startAt: 1,
      breakpoints: {
        576: {
          perView: 3,
          focusAt: 2,
        },
        1000: {
          perView: 5,
          focusAt: 3
        },
        1199: {
          perView: 7,
          focusAt: 4
        }
      }
    });

    var self = this;
    var slices;
    //var itemIsActive;

    this.glider.on(['run.before'], (event) => {
      /*
      var allItemsActive = document.querySelectorAll('.glide__slide--active');
          allItemsActive.forEach(element => {
            if(element.id.includes('productCategory')){
              itemIsActive = element;
            }
           });
           */
    
          if (event.direction == ">" && !self.enterFirstTime) {
            if (self.glider.index == 0) {
              self.active = document.getElementById(self.products[self.products.length - 1].id + "-productCategory").nextElementSibling.children[0];
              self.activeIndex = self.products[self.products.length - 1].id
            }
            else {
              self.active = document.querySelector('.glide__slide--active').children[0];
              slices = document.querySelector('.glide__slide--active').id.split("-");
              self.activeIndex = slices[0];
            }
          }
          else if(event.direction == "<" && !self.enterFirstTime) {
            if(self.glider.index == 0){
              var array = document.querySelectorAll("[id='"+ self.products[self.products.length - 2].id + "-productCategory" + "']");
              self.active = array[1].children[0];
              slices = array[1].id.split("-");
              self.activeIndex = slices[0];
            }
            else{
              self.active = document.querySelector('.glide__slide--active').previousElementSibling.previousElementSibling.children[0];
              slices = document.querySelector('.glide__slide--active').previousElementSibling.previousElementSibling.id.split("-");
              self.activeIndex = slices[0];
            }
          }
    
          //enter first time
          /*
          if (self.glide.index == 0 && self.enterFirstTime) {
            self.active = itemIsActive.previousElementSibling.children[0];
            slices = itemIsActive.id.split("-");
            self.activeIndex = slices[0];
            self.enterFirstTime = false;
          }
          */


          //last item
          if(self.glider.index == self.products.length - 1){
            if(event.direction == ">"){
              self.active = document.getElementById(self.products[self.products.length - 1].id + "-productCategory").children[0];
              self.activeIndex = self.products[self.products.length - 1].id;
            }
            else{
              self.active = document.querySelector('.glide__slide--active').previousElementSibling.previousElementSibling.children[0];
              slices = document.querySelector('.glide__slide--active').previousElementSibling.previousElementSibling.id.split("-");
              self.activeIndex = slices[0];
            }
          }

          if(self.products.length == 1){
            self.active = document.querySelector('.glide__slide--active').previousElementSibling.children[0];
            slices = document.querySelector('.glide__slide--active').previousElementSibling.id.split("-");
            self.activeIndex = slices[0];
          }
 
          this.tl.progress(0).clear();
    
          if (self.prevItem != null) {
            self.tl.to(self.prevItem, 1, { height: "85%", ease: Power2.easeOut})
              .to(self.active, 1, { height: "100%", ease: Power2.easeOut}, 0.5);
          }
          else {
            self.tl.to(self.active, 0.5, { height: "100%", ease: Power2.easeOut });
          }
          if(self.activeProductId == 20){ //fix for water
            self.change.emit(19);
          }
          else{
            self.change.emit(self.activeIndex);
          }
          self.prevItem = self.active;
          self.enterFirstTime = false;
    });

    this.glider.mount();

    setTimeout(() => {
      var activeItem1 = document.querySelector('.glide__slide--active').previousElementSibling.children[0];

      var slice = document.querySelector('.glide__slide--active').previousElementSibling.id.split("-");
      this.activeIndex = slice[0];
      this.currentItem = this.dataService.getProduct(self.activeIndex);
      this.tl.to(activeItem1, 0.5, { height: "100%", ease: Power2.easeOut });
     }, 160);
  }
}
