<div class="pressContainer">
    <app-header class="header" [name]="'DOWNLOADS'" [type]="'drei_schwestern'"></app-header>

    <div class="pressInstructionContainer">
        <div class="introText">Downloadbereich</div>
        <!--<div class="introContent">Finden Sie hier unsere Logos, Bilder und Produktdatenblätter zum Download.</div>-->
        <div class="introContent">Hier kannst du das VO ÜS Logo für Promotionszwecke herunterladen.<br>
            Verfügbar sind die Dateien in Farbe und Schwarz/Weiß im PNG-Format. Weitere Formate auf Anfrage.<br>
            Vor der Veröffentlichung ist das Layout zur Freigabe an folgende Adresse zu mailen:<br>
            <a href="mailto:info@voüs.at">info@voüs.at</a>
            </div>
            <!--
        <div class="buttonsContainer">
            <app-button (click)="getAllDownloads()" [name]="'Alle'" class="buttonItem"></app-button>
            <app-button (click)="getDownloadsByCategory('Logo')" [name]="'Logos'" class="buttonItem"></app-button>
            <app-button (click)="getDownloadsByCategory('Produktabbildung')" [name]="'Flaschen'" class="buttonItem"></app-button>
            <app-button (click)="getDownloadsByCategory('Produktdatenblatt')" [name]="'Produktdatenblätter'" class="buttonItem"></app-button>
        </div>
    -->
    </div>

    <div class="gridContainer">
        <div class="gridItem" *ngFor="let item of downloads">
            <div class="imageContainer">
                <img class="previewImg" *ngIf="item.type != 'pdf'" [src]="item?.downloadUrl"/>
                <img class="previewImg" *ngIf="item.type == 'pdf'" src="../../../assets/imgs/pdfPreview.jpg"/>
            </div>
            <div class="descContainer">
                <div class="descText">
                    <h1 class="header">{{item?.name}}</h1>
                    <h3 class="category">{{item?.category}}</h3>
                </div>
                <div class="downloadContainer">
                    <button class="downloadButton" (click)="downloadFile(item)">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>