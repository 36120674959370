<div class="gliderContainer" [ngClass]="class">
  <div class="glide__track" data-glide-el="track">
    <div class="glide__slides">
      <div class="glide__slide" *ngFor="let item of data">
      <div class="imageContainerSlider">
        <img class="image" [src]="item?.image" />
      </div>
      <div class="contentContainer">
        <div class="contentHeader">
          {{item?.name}}
        </div>
        <div class="content">
          {{item?.shortDesc}}
        </div>
        <app-button [name]="'Mehr erfahren'" [routerLink]="['/',route,item.id]"></app-button>
      </div>
    </div>
    </div>
  </div>
  <div class="glideArrows" data-glide-el="controls">
    <div class="glideArrow arrowLeft" data-glide-dir="<">
      <img class="arrowIconleft" src="../../../assets/imgs/pfeil_unten.svg"/>
    </div>
    <div class="glideArrow arrowRight" data-glide-dir=">">
      <img class="arrowIconRight" src="../../../assets/imgs/pfeil_unten.svg"/>
    </div>
  </div>

  <div class="dotsContainer">
    <div class="bulletsEmty"></div>
    <div class="glide__bullets">
      <div *ngFor="let item of data; index as i" data-glide-el="controls[nav]">
        <button *ngIf="i == 0" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=0"></button>
        <button *ngIf="i == 1" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=1"></button>
        <button *ngIf="i == 2" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=2"></button>
        <button *ngIf="i == 3" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=3"></button>
        <button *ngIf="i == 4" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=4"></button>
        <button *ngIf="i == 5" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=5"></button>
        <button *ngIf="i == 6" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=6"></button>
        <button *ngIf="i == 7" class="glide__bullet" [class.active]="i == activeIndex" data-glide-dir="=7"></button>
      </div>
    </div>
  </div>
</div>

