import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { gsap } from 'gsap';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Die Story.");
    this.meta.addTag({ name: 'description', content: 'Wie aus zwei Vorarlberger Brauereien und einer Idee VO ÜS entstand.'});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Die Story.' });
    this.meta.addTag({ name: 'og:description', content: 'Wie aus zwei Vorarlberger Brauereien und einer Idee VO ÜS entstand.'});
    window.scroll(0,0);
  }

  ngAfterViewInit(){
    window.scroll(0,0);

    gsap.from(".contentItem", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
  }

}
