<div class="createdFromContainer">
    <app-header class="header" [name]="'GEMACHT VO ÜS'" [type]="'headerGemacht'"></app-header>

    <div class="descriptionContainer" id="inhalt">
        <div class="contentContainer">
            <div class="intro">DER INHALT</div>
            <div class="header">Genau so. Und nicht einen Schluck anders.</div>
            <div class="content">
                VO ÜS heißt nicht ohne Grund VO ÜS und ist nicht nur eine Marke. VO ÜS ist unsere Haltung und unsere Identität, unsere Herkunft und unsere Art zu handeln. Präzise in der Produktion und mit der Portion Liebe, die du von uns erwartest.<br>
                <br>
                Darum sind unsere Getränke so geworden, wie sie sind. Aus guten Rohstoffen und besten Früchten, mit klarem Vorarlberger Wasser. Und mit einer grundsätzlichen Entscheidung: alles wegzulassen, was nicht unbedingt in die Getränke hinein muss.<br>
                <br>
                VO ÜS ist der Geschmack, wie er nur VO ÜS kommen kann. Genau so muss es sein: schließlich bist auch du VO ÜS.
            </div>
        </div>
        <div class="logoContainer">
            <img class="image" src="../../../assets/imgs/gemacht_icon_1.svg"/>
        </div>
    </div>

    <div class="stickyImageContainer">
    </div>

    <div class="descriptionContainer" id="verantwortung">
        <div class="contentContainer">
            <div class="intro">DIE VERANTWORTUNG</div>
            <div class="header">Wenn wir dich frisch machen, dann auch nachhaltig.</div>
            <div class="content">
                Wir Vorarlberger sind respektvoll: Unserer Natur, unserer gemeinsamen Zukunft, unseren Mitmenschen und Kindern gegenüber. Aber auch wenn VO ÜS vo do kommt, endet unser Horizont weder am Bodensee, noch am Bergpanorama, sondern wir schauen täglich in die Weite. Darauf zu achten, woher unsere Früchte kommen, dem Klima mit einem geschlossenen Wertstoffkreislauf zu helfen und akribisch verantwortungsvoll mit Ressourcen umzugehen, ist uns mindestens so wichtig, wie du und dein Durst.<br>
                <br>
                Darum bekommst du VO ÜS in der Glas-Pfandflasche und nur als PET, wenn es (zum Beispiel auf Veranstaltungen) gesetzlich vorgeschrieben ist.
            </div>
        </div>
        <div class="logoContainer">
            <img class="image" src="../../../assets/imgs/gemacht_icon_2.svg"/>
        </div>
    </div>

    <div class="stickyImageContainer">
    </div>

    <div class="descriptionContainer" id="engagement">
        <div class="contentContainer">
            <div class="intro">DAS ENGAGEMENT</div>
            <div class="header">Wer Durst löschen kann, kann auch noch ganz andere Dinge.</div>
            <div class="content">
                Wir von VO ÜS leben gern in Vorarlberg – kein Wunder, schließlich ist unser Zuhause in vielerlei Hinsicht schön. Schönheit, die erhalten werden will. Darum setzen wir uns nicht nur für deinen Durst ein, sondern auch für ausgesuchte Projekte.
            </div>
        </div>
        <div class="logoContainer">
            <img class="image" src="../../../assets/imgs/gemacht_icon_3.svg"/>
        </div>
    </div>

    <div class="moreContainer">
        <div class="header">
            MEHR VO ÜS:
        </div>
        <div class="slider">
            <div class="sliderContainer">
                <draggable-slider [type]="'news'"></draggable-slider>
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
