<div class="pressContainer">
    <app-header class="header" [name]="'PRESSE-KIT'" [type]="'zimba'"></app-header>

    <div class="pressInstructionContainer">
        <div class="introText">Download & Pressebereich</div>
        <div class="introContent">Finden Sie hier unsere Presseinformationen und Pressefotos.</div>
    </div>

    <div class="gridContainer">
        <div class="gridItem" *ngFor="let item of downloads | async">
            <div class="imageContainer">
                <img class="previewImg" *ngIf="item.type != 'pdf'" [src]="item?.downloadUrl"/>
                <img class="previewImg" *ngIf="item.type == 'pdf'" src="../../../assets/imgs/pdfPreview.jpg"/>
            </div>
            <div class="descContainer">
                <div class="descText">
                    <h1 class="header">{{item?.name}}</h1>
                    <h3 class="category">{{item?.category}}</h3>
                </div>
                <div class="downloadContainer">
                    <button class="downloadButton" (click)="downloadFile(item)">Download</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>