import { Component, OnInit } from '@angular/core';
import { TweenLite, Power2, TimelineLite } from 'gsap';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  ingredientTl = new TimelineLite({paused:true, reversed: true});

  isMobile: boolean = false;

  product: any;
  activeCategory: any;
  activeCategories: any = [];

  screenWidth: any = 0;

  initFinished: boolean = false;

  showMoreDetails: boolean = false;

  activeImage: any;

  constructor(private dataService: DataService, private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta) 
  {
    this.checkWidth();

      this.activatedRoute.url.subscribe((data)=>{
        var path = data[0].path;
        if(path == "productDetail"){
          var productID = this.activatedRoute.snapshot.paramMap.get('id');
          this.product = this.dataService.getProduct(productID);
          this.activeCategory = this.product.category;
          this.activeCategories = this.dataService.getProductsOfCategory(this.product.category);
        }
        else if(path == "limonade"){
          this.activeCategories = this.dataService.getProductsOfCategory('Limo');
          this.product = this.dataService.getProduct(this.activeCategories[0].id);
          this.activeCategory = this.product.category;
        }
        else if(path == "saefte"){
          this.activeCategories = this.dataService.getProductsOfCategory('Saft');
          this.product = this.dataService.getProduct(this.activeCategories[0].id);
          this.activeCategory = this.product.category;
        }
        else if(path == "eistee"){
          this.activeCategories = this.dataService.getProductsOfCategory('Eistee');
          this.product = this.dataService.getProduct(this.activeCategories[0].id);
          this.activeCategory = this.product.category;
        }
        else if(path == "wasser"){
          this.activeCategories = this.dataService.getProductsOfCategory('Wasser');
          this.product = this.dataService.getProduct(this.activeCategories[0].id);
          this.activeCategory = this.product.category;
        }
        else{
          this.activeCategories = this.dataService.getProductsOfCategory('Limo');
          this.product = this.dataService.getProduct(this.activeCategories[0].id);
          this.activeCategory = this.product.category;
        }
        this.activeImage = this.product.sizes[0].image;
      }); 
  }

  ngOnInit(): void {
    this.title.setTitle("So schmeckt’s VO ÜS » Unsere Getränke.");
    this.meta.addTag({ name: 'description', content: 'Limo, Säfte, Eistee, Wasser, Cola. Prickelnd, still, fruchtig und überhaupt alles, was dein Geschmack VO ÜS erwartet. '});
    this.meta.addTag({ name: 'og:title', content: 'So schmeckt’s VO ÜS » Unsere Getränke.' });
    this.meta.addTag({ name: 'og:description', content: 'Limo, Säfte, Eistee, Wasser, Cola. Prickelnd, still, fruchtig und überhaupt alles, was dein Geschmack VO ÜS erwartet. '});
    window.scroll(0,0);
    window.addEventListener('resize', this.checkWidth);
    const tl = new TimelineLite();
    tl.from('.productImage', 1.4, {x: -80, opacity: 0, ease: Power2.easeOut})
    .from('.productCategoriesContainer', 0.5, {width: '0%', opacity: 0, ease: Power2.easeOut}, 0.4);
  }

  ngAfterViewInit(){
    this.initAnimationIngredients()
  }

  initAnimationIngredients(){
    if(!this.isMobile  || this.activeCategories?.length == 1){
      this.ingredientTl.to('.productCategoriesContainer', 1, { width: '0%', opacity: 0, ease: Power2.easeOut})
      .to('navbar', 1, {display: 'none', ease: Power2.easeOut}, 0)
      .to('.productsOfCategoryContainer', 1, {opacity: 0, ease: Power2.easeOut}, 0)
      .fromTo('.productDetailContent', 1,{ width: '100%'}, { width: '70%'}, '-=0.8')
      .fromTo('.ingredientsContainer', 1, {width: '0%', opacity: 0 ,ease: Power2.easeOut}, {width: '30%', opacity: 1 ,ease: Power2.easeOut}, '-=1');
    }
    else{
      this.ingredientTl.to('.productCategoriesContainer', 1, { width: '0%', opacity: 0, ease: Power2.easeOut})
      .to('navbar', 1, {display: 'none', ease: Power2.easeOut}, 0)
      .to('.ingredientsContainer', 1, {position: 'absolute', top: '0', left: '0', height: '100%', width: '100%', zIndex: 2, opacity: 1});
    }
  }

  toogleIngredientsAnimation(){
    this.ingredientTl.reversed() ? this.ingredientTl.play() : this.ingredientTl.reverse();
  }

  public checkWidth() {
    var width = window.innerWidth;
    if (width <= 768) {
        this.isMobile = true;
    } else if (width > 768 && width <= 992) {
        this.isMobile = false;
    } else {
        this.isMobile = false;
    }
  }

  changePreviewImage(image){
    this.activeImage = image;
  }

  showMore(event){
    if(!this.showMoreDetails){
      event.srcElement.classList.remove("productDescriptionMobile");
      this.showMoreDetails = true;
    }
    else{
      event.srcElement.classList.add("productDescriptionMobile");
      this.showMoreDetails = false;
    }
  }

  //slider changed
  /*
  openProduct(id){
    if(this.initFinished){
      this.product = this.dataService.getProduct(id);
      this.activeCategory = this.product.category;
    }
    else{
      this.initFinished = true;
    }
  }
  */

  //slider changed mobile
  changeItemMobile(id){
    this.product = this.dataService.getProduct(id);
    this.activeImage = this.product.sizes[0].image;
  }

  //category-of-product changed
  productChanged(item){
    this.product = item;
    this.activeCategory = this.product.category;
    this.activeImage = this.product.sizes[0].image;
  }

  //choose category manually
  changeCategory(category){
    this.activeCategory = category;
    var cateogryItems = this.dataService.getProductsOfCategory(this.activeCategory);
    this.activeCategories = this.dataService.getProductsOfCategory(this.activeCategory);
    this.product = this.dataService.getProduct(cateogryItems[0].id);
    this.activeImage = this.product.sizes[0].image;
  }
}
