import { Component, OnInit } from '@angular/core';
import {gsap} from 'gsap';
import { HostListener } from '@angular/core';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navigationOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    let element = document.querySelector('.navBar');
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  animateNavIcon(){
    gsap.to(".top", {y: -2.5});
    gsap.to(".bottom",{y: 2.5});
  }

  animateNavIconLeave(){
    gsap.to(".top", {y: 0});
    gsap.to(".bottom",{y: 0});
  }

  toggleNavigation(){
    if(this.navigationOpen){
      this.navigationOpen = false;
    }
    else{
      this.navigationOpen = true;
      setTimeout(() => {
        gsap.fromTo(".animateNavBlock", {
          y: -30,
        }, {opacity: 1, stagger: 0.1});
      }, 0.1);
    }
  }

  itemClicked(){
    this.navigationOpen = false;
  }

  goHome(){
    this.navigationOpen = false;
  }

}
