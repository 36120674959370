<nav class="navBar" [class.navigationOpen]="navigationOpen">
    <div [routerLink]="['home']" class="headerContainer" (click)="goHome()">
        <img *ngIf="navigationOpen" class="headerIcon" src="../../../assets/imgs/navi_logo_weiss.svg"/>
        <img *ngIf="!navigationOpen" class="headerIcon" src="../../../assets/imgs/navi_logo_blau.svg"/>
    </div>
    <div class="navigationIcon" *ngIf="!navigationOpen" (mouseenter) ="animateNavIcon()" (mouseleave) ="animateNavIconLeave()" (click)="toggleNavigation()">
        <div class="iconLine top"></div>
        <div class="iconLine"></div>
        <div class="iconLine bottom"></div>
    </div>
    <div class="navigationIcon" *ngIf="navigationOpen" (click)="toggleNavigation()">
        <img src="../../../assets/imgs/navi_x.svg"/>
    </div>
</nav>

<div class="navigationContaner" *ngIf="navigationOpen" (click)="itemClicked()">
    <div class="navigationItem">
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="nav-link" routerLink="home" routerLinkActive="active">Daheim</a>
            </div>
        </div>
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="nav-link" routerLink="news" routerLinkActive="active">News VO ÜS</a>
            </div>
        </div>
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="navHeader" routerLink="limonade">So schmeckt's VO ÜS</a>
            </div>
            <div class="navItem desktop">
                <div class="navIconContainer">
                    <img class="navIcon" src="../../../assets/imgs/navi_limo.svg"/>
                </div>
                <a class="nav-link" routerLink="limonade" routerLinkActive="active">Limonaden</a>
            </div>
            <div class="navItem desktop">
                <div class="navIconContainer">
                    <img class="navIcon" src="../../../assets/imgs/navi_saefte.svg"/>
                </div>
                <a class="nav-link" routerLink="saefte" routerLinkActive="active">Säfte</a>
            </div>
            <div class="navItem desktop">
                <div class="navIconContainer">
                    <img class="navIcon" src="../../../assets/imgs/navi_eistee.svg"/>
                </div>
                <a class="nav-link" routerLink="eistee" routerLinkActive="active">Eistee</a>
            </div>
            <div class="navItem desktop">
                <div class="navIconContainer">
                    <img class="navIcon" src="../../../assets/imgs/navi_wasser.svg"/>
                </div>
                <a class="nav-link" routerLink="wasser" routerLinkActive="active">Wasser</a>
            </div>
            <div class="navItem mobile">
                <a class="nav-link" routerLink="productDetail/1" routerLinkActive="active">Sorten</a>
            </div>
        </div>
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="navHeader" routerLink="story">Es kommt VO ÜS</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="story" routerLinkActive="active">Die Story</a>
            </div>
        </div>
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="navHeader" routerLink="inhalt">Gemacht VO ÜS</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="inhalt" routerLinkActive="active">Der Inhalt</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="verantwortung" routerLinkActive="active">Die Verantwortung</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="engagement" routerLinkActive="active">Das Engagement</a>
            </div>
        </div>
        <div class="navBlock animateNavBlock">
            <div class="navItem">
                <a class="navHeader" routerLink="hiergibtsvoues">Hier gibt's VO ÜS</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="locations" routerLinkActive="active">Händler & Gastronomie</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="press" routerLinkActive="active">Presse/Downloads</a>
            </div>
            <div class="navItem">
                <a class="nav-link" routerLink="contact" routerLinkActive="active">Kontakt</a>
            </div>
        </div>
    </div>
    <div class="socialMediaContainer">
        <div class="socialMediaItem text">
            Folge Üs
        </div>
        <div class="socialMediaItem">
            <a class="link" href="https://www.facebook.com/limovoues/" target="_blank">
                <img class="logo" src="../../../assets/imgs/navi_fb.svg"/>
            </a>
        </div>
        <div class="socialMediaItem">
            <a class="link" href="https://www.instagram.com/vo_ues/?hl=de" target="_blank">
                <img class="logo" src="../../../assets/imgs/navi_insta.svg"/>
            </a>
        </div>
    </div>
    <div class="copyRightContainer">
        Copyright 2020 VO ÜS Vorarlberger Limo Werk GmbH
    </div>
</div>
