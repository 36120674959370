import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.scss']
})
export class ArticleItemComponent implements OnInit {

  @Input() name: any;
  @Input() image: any;
  @Input() id: any;
  @Input() route: any;

  constructor() { }

  ngOnInit(): void {
  }

}
