<div class="storyContainer">
    <div class="headerContainer">
        <img class="headerImage" src="../../../assets/imgs/storyHeader.png"/>
        <div class="headerText">
            <h1 class="header">NICHT VON IRGENDWO</h1>
        </div>
    </div>
    <div class="storyInstructionContainer">
        <div class="header">ES KOMMT VO ÜS - DIE STORY</div>
        <div class="header2">Es waren einmal zwei große Brauereien in einem kleinen Land ...</div>
        <div class="content">
            So beginnen gute Geschichten, nur dass unsere vielleicht noch ein wenig leckerer, erfrischender und fruchtiger ist. Und nur hier erzählt werden kann, denn wir Vorarlberger hatten schon immer unseren eigenen Kopf, voller eigener Ideen. Getränke zu machen, beispielsweise.
        </div>
    </div>
    <div class="contentItem">
        <div class="contentColumn column1">
            <div class="content">Vorarlberg ist ein kleines Land mit einem großen Herzen: Wir kennen und schätzen uns gegenseitig. Und haben einen hohen Anspruch an uns und das, was bei uns entsteht. Wenn man hierzulande etwas anpackt, macht man’s auch gleich richtig gut. Mit Freude, ehrlich und echt. Am besten gemeinsam.</div>
            <div class="content">Eines sonnigen Tages steckten wir, die Brauereien Frastanzer und Mohren, die Köpfe zusammen und dachten darüber nach, woran man an sonnigen Tagen eben so denkt: an Limo. Und wenn man nomalerweise Bier braut, denkt man bei der Gelegenheit auch gleich ein Stückchen weiter: nämlich daran, selber Limo herzustellen. Aber nur eine, die man selber wirklich gerne trinken möchte.</div>
        </div>
        <div class="contentColumn">
            <img class="image" src="../../../assets/imgs/story/Vorarlberg.jpg"/>
        </div>
    </div>
    <div class="contentItem content2">
        <div class="contentColumn">
            <img class="image" src="../../../assets/imgs/story/Zuhause.jpg"/>
        </div>
        <div class="contentColumn column2">
            <!--<div class="header">Lorem Ipsum dolar sit amet</div>-->
            <div class="content">Weil echte Vorarlberger nicht nur denken, sondern machen, haben wir gemacht: leckere Limonaden, süffige Säfte, frisches Wasser und erfrischenden Eistee. Als Partner und Freunde für die, die Gutes trinken wollen. Für euch. Darum haben wir unsere Marke auch gleich so genannt, wie sie nur hier heißen kann: VO ÜS. Aus Vorarlberg. Nicht von irgendwo.</div>
            <div class="content bold">Herzlich willkommen zuhause.</div>
        </div>
    </div>

    <div class="sliderContainer">
        <div class="header">MEHR VO ÜS:</div>
        <div class="slider">
            <div class="sliderContainer">
                <draggable-slider [type]="'news'"></draggable-slider>
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
