import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { gsap, TimelineLite, Power2 } from 'gsap';

@Component({
  selector: 'products-of-category',
  templateUrl: './products-of-category.component.html',
  styleUrls: ['./products-of-category.component.scss']
})
export class ProductsOfCategoryComponent implements OnInit {

  @Input() set currentProduct(val: any){
    this.activeItem = val;
    this.itemChanged = false;
    this.getProdctsOfCategory();
  }

  @Input() set setCategory(val: any){
    //this.products = this.dataService.getProductsOfCategory(val);
    //this.activeItem = this.dataService.getProduct(this.products[0].id);
    //this.animateActiveItem();
  }

  @Output() productChanged = new EventEmitter();

  tl = new TimelineLite();

  activeItem: any;
  products: any;
  prevItem: any = null;

  itemChanged: boolean = false;

  screenWidth: any;
  itemHeight: any = "80%";
  activeItemHeight: any = "100%";

  constructor(private dataService: DataService) 
  {
  }

  animateActiveItem(){
    if(!this.itemChanged){
      setTimeout(() => {
      var active = document.getElementById(this.activeItem.id + "productOfCategory").children[0];
      var self = this;
      if(this.prevItem != null && this.prevItem != active){
        self.tl.to(self.prevItem, 0.7, {height: self.itemHeight, opacity: 0.7, ease: Power2.easeOut})
        .to(active, 0.5, {height: self.activeItemHeight, opacity: 1, ease: Power2.easeOut}, 0.4);
      }
      else{
        gsap.to(active,1,{opacity: 1, height: self.activeItemHeight});
      }
      this.prevItem = active;
    }, 2);
    }
  }

  getProdctsOfCategory(){
    this.products = this.dataService.getProductsOfCategory(this.activeItem.category);
    this.animateActiveItem();
  }

  ngOnInit(): void {
    }

    ngAfterViewInit(){
      this.windowResized();
      this.getProdctsOfCategory();
    }

   productClicked(product){
     this.activeItem = product;
     this.animateActiveItem();
      this.productChanged.emit(product);
      this.itemChanged = true;
   }

   windowResized(){
    this.screenWidth = window.innerWidth;
  }
  }
