<div class="productsOfCategoryContainer">
  <div
    class="productItem"
    *ngFor="let item of products"
    [id]="item.id + 'productOfCategory'"
    (click)="productClicked(item)"
  >
    <img
      class="productImage"
      [src]="item?.sizes[0].image"
      [ngStyle]="{
        filter: item?.soldOut == true ? 'grayscale(0.9)' : 'grayscale(0)'
      }"
    />
  </div>
</div>
