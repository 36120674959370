<!--PRODUCT SECTION-->
<section class="productSection">
  <div class="productHeader">
    <h1 class="header">
      DAS LÄNDLE<br />
      JETZT ENDLICH AUCH ZUM TRINKEN.<br /><br />
      VO ÜS.<br />
      FÜR DICH. AUS VORARLBERG. NICHT VON IRGENDWO.
    </h1>
  </div>
  <div class="productSlider">
    <product-slider
      [autoplay]="4000"
      [location]="'productOfSlider'"
    ></product-slider>
  </div>

  <img class="cloudImage" src="../../../assets/imgs/wolke.png" />
  <img class="cloudImage2" src="../../../assets/imgs/wolke.png" />
</section>

<!--NEWS SECTION-->
<section class="newsSection">
  <div class="headerContainer">
    <h1 class="headerText">NEWS VO ÜS</h1>
    <img class="birdImage" src="../../../assets/imgs/vogel.png" />
    <img class="newsCloadImage" src="../../../assets/imgs/wolke.png" />
  </div>
  <div class="slider">
    <draggable-slider [type]="'news'"></draggable-slider>
  </div>
  <img class="redDot" src="../../../assets/imgs/redDot.jpg" />
</section>

<!--INFO SECTION-->
<!--
<section class="infoSection">
    <div class="headerContainer">
        <h1 class="headerText">VO ÜS: NICHT VON IRGENDWO</h1>
        <img class="bottleImage" src="../../../assets/imgs/bootle.png"/>
        <img class="cloadImageInfo1" src="../../../assets/imgs/wolke.png"/>
        <img class="cloadImageInfo2" src="../../../assets/imgs/wolke.png"/>
    </div>
    <div class="slider">
        <div class="sliderContainer">
            <draggable-slider [type]="'info'"></draggable-slider>
        </div>
    </div>
</section>
-->

<!--FOOTER-->
<app-footer></app-footer>
