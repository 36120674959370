<div class="contactContainer">
    <app-header class="header" [name]="'Kontakt'" [type]="'vallüla'"></app-header>
    <div class="instructionContainer">
        <div class="header">
            <div>Hast du etwas </div>
            <div>vo üs?</div>
        </div>
        <!--
        <div class="content">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labo.
        </div>
        -->
    </div>

    <form [formGroup]="contactForm" class="formGroup" (ngSubmit)="sendMail()">
        <div class="contactFormContainer">
            <input class="inputItem" type="text" formControlName="subject" placeholder="Was hast du vo üs?*"/>
            <div class="contactInfo">
                <input class="inputInfoItem" type="email" formControlName="email" placeholder="E-Mail-Adresse*"/>
                <input class="inputInfoItem" type="number" formControlName="phoneNumber" placeholder="Telefonnummer"/>
            </div>
            <input class="inputItem" type="text" formControlName="fullName" placeholder="Name*"/>
            <textarea class="textArea" formControlName="message" placeholder="Nachricht*"></textarea>
            <div class="infoContainer">* Pflichtfelder</div>
            <div class="sendContainer">
                <div class="checkBoxContainer">
                    <input class="checkBox" type="checkbox" formControlName="readAGBs"/>
                    <div>Ich habe die Information zum Datenschutz gelesen und akzeptiert</div>
                </div>
                <button class="button" type="submit" [disabled]="contactForm.get('subject').hasError('required') || contactForm.get('fullName').hasError('required') || contactForm.get('email').hasError('required') || contactForm.get('message').hasError('required') || contactForm.get('readAGBs').hasError('required')">Abschicken</button>
            </div>
            <div class="messageOutput">{{message}}</div>
        </div>
    </form>

    <div class="mapContainer">
        <div class="map">
            <agm-map [latitude]="lat" [zoom]="zoom" [longitude]="lng">
                <agm-marker  [latitude]="lat" [longitude]="lng"></agm-marker>
              </agm-map>
        </div>
        <div class="content">
            <div class="header">VO ÜS Limo Werk GmbH</div>
            <div class="contentItem">Millennium Park 10</div>
            <div class="contentItem">A-6890 Lustenau</div>
            <a class="contentItem" href="tel:+43-5572-3777-0">Tel. + 43 - 55 72 - 37 77 - 0</a>
            <div class="contentItem">Fax: + 43 - 55 72 - 37 77 - 41 56</div>
            <a class="contentItem" href="mailto:info@voüs.at">info@voüs.at</a>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
