import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { StoryComponent } from './pages/story/story.component';
import { PressComponent } from './pages/press/press.component';
import { PressArticleComponent } from './pages/press-article/press-article.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { CreatedFromComponent } from './pages/created-from/created-from.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { AgbComponent } from './pages/agb/agb.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';
import { PressKitComponent } from './pages/press-kit/press-kit.component';
import { LiablityCommitteeComponent } from './pages/liablity-committee/liablity-committee.component';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'news', component: NewsComponent},
  {path: 'productDetail/:id', component: ProductDetailComponent},
  {path: 'limonade', component: ProductDetailComponent},
  {path: 'saefte', component: ProductDetailComponent},
  {path: 'eistee', component: ProductDetailComponent},
  {path: 'wasser', component: ProductDetailComponent},
  {path: 'story', component: StoryComponent},
  {path: 'dieStory', component: StoryComponent},
  {path: 'press', component: PressComponent},
  {path: 'pressArticle/:id', component: PressArticleComponent},
  {path: 'newsArticle/:id', component: PressArticleComponent},
  {path: 'infoArticle/:id', component: PressArticleComponent},
  {path: 'hiergibtsvoues', component: LocationsComponent},
  {path: 'locations', component: LocationsComponent},
  {path: 'createdFrom', component: CreatedFromComponent},
  {path: 'inhalt', component: CreatedFromComponent},
  {path: 'verantwortung', component: CreatedFromComponent},
  {path: 'engagement', component: CreatedFromComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'about', component: AboutComponent},
  {path: 'datenschutz', component: AboutComponent},
  {path: 'agb', component: AgbComponent},
  {path: 'downloads', component: DownloadsComponent},
  {path: 'pressKit', component: PressKitComponent},
  {path: 'teilnahmebedingungen', component: LiablityCommitteeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
