<div class="locationsContainer">
  <app-header
    class="header"
    [name]="'HIER GIBT\S VO ÜS'"
    [type]="'insel'"
  ></app-header>

  <div class="descriptionContainer">
    <div class="contentContainer">
      <div class="intro">Die Quellen</div>
      <div class="header">Vo do bekommst du VO ÜS.</div>
      <div class="content">
        Genug gelesen, jetzt hast du Durst. Hier ist ersichtlich, wo du dein
        Getränk VO ÜS kaufen kannst. Bis auf einige Ausnahmen ist VO ÜS momentan
        nur in Vorarlberg erhältlich. Du bekommst VO ÜS auf jeden Fall in
        ausgewählten Vorarlberger und Tiroler Gastronomiebetrieben.
      </div>
      <br />
      <div class="content">
        <strong>Außerdem erhältst du hier in Vorarlberg VO ÜS:</strong>
      </div>
      <div class="content">• Spar</div>
      <div class="content">• Sutterlüty</div>
      <div class="content">• Billa</div>
      <div class="content">• BILLA PLUS</div>
      <div class="content">• ADEG</div>
      <div class="content">• Metro</div>
      <div class="content">• AGM</div>
      <div class="content">• BayWa Bau & Gartenmarkt</div>
      <br />
      <div class="content"><strong>So kommst du in Wien zu VO ÜS:</strong></div>
      <div class="content">• GRUNDBIRA Vorarlberger Spezialitäten</div>
      <br />
      <div class="content">
        Bitte denke daran, dass wir keine Garantie für die Verfügbarkeit vor Ort
        sowie die Aktualität dieser Bezugsquellen übernehmen können. Darum ist
        die direkte Anfrage beim Händler deiner Wahl eine gute Idee und
        verhindert einen Weg, an dessen Ende es kein Getränke VO ÜS gibt.
      </div>
      <br />
    </div>
    <div class="logoContainer">
      <img class="image" src="../../../assets/imgs/hier_gibts_geopin.svg" />
    </div>
  </div>

  <!-- <div class="mapsContainer">
    <agm-map
      [latitude]="locations[0]?.cords.lat"
      [longitude]="locations[0]?.cords.lng"
      [zoom]="zoom"
    >
      <agm-marker
        *ngFor="let marker of locations"
        [latitude]="marker?.cords.lat"
        [longitude]="marker?.cords.lng"
        (markerClick)="openWindow(marker.id)"
      >
        <agm-info-window
          [isOpen]="isInfoWindowOpen(marker.id)"
          [latitude]="marker.lat"
          [longitude]="marker.lng"
        >
          <div class="infoWindowContainer">
            <div class="headerInfoWindow">{{ marker?.name }}</div>
            <div class="contentInfoContainer">
              {{ marker?.street }}<br />
              {{ marker?.city }}<br />
              {{ marker?.phone }}<br />
              <a href="mailto:{{ marker?.email }}">{{ marker?.email }}</a
              ><br />
              <a href="marker?.website">{{ marker?.website }}</a>
            </div>
          </div>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div> -->

  <div class="moreContainer">
    <div class="header">MEHR VO ÜS:</div>
    <div class="slider">
      <div class="sliderContainer">
        <draggable-slider [type]="'news'"></draggable-slider>
      </div>
    </div>
  </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
