import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { gsap } from 'gsap';

@Component({
  selector: 'app-created-from',
  templateUrl: './created-from.component.html',
  styleUrls: ['./created-from.component.scss']
})
export class CreatedFromComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private title: Title, private meta: Meta) 
  {
    
  }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Inhalt, Verantwortung, Engagement.");
    this.meta.addTag({ name: 'description', content: 'Und sonst so? Alles, was du über die Getränke und die Marke VO ÜS schon immer wissen wolltest.'});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Inhalt, Verantwortung, Engagement.' });
    this.meta.addTag({ name: 'og:description', content: 'Und sonst so? Alles, was du über die Getränke und die Marke VO ÜS schon immer wissen wolltest.'});
    window.scroll(0,0);
  }

  ngAfterViewInit(){
    this.activatedRoute.url.subscribe((data)=>{
      var path = data[0].path;
      if(path == "inhalt"){
        window.scroll(0,0);
      }
      else if(path == "verantwortung"){
        let el = document.getElementById('verantwortung');
        const y = el.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
      else if(path == "engagement"){
        let el = document.getElementById('engagement');
        const y = el.getBoundingClientRect().top + window.pageYOffset - 80;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
      else{
        window.scroll(0,0);
      }
    });

    gsap.from(".descriptionContainer", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
  }

}
