<div class="pressArticleContainer">
    <div class="headerContainer">
        <img *ngIf="!isMobile" class="image" [src]="articleData?.headerImage"/>
        <img *ngIf="isMobile" class="image" [src]="articleData?.image"/>
    </div>

    <div class="articleContentContainer">
        <div class="date">{{articleData?.releaseDate.toDate() | date:'dd.MM.yyyy'}}</div>
        <div class="headerText">{{articleData?.name}}</div>
        <div class="content" [innerHTML]="articleData?.description">
        </div>
    </div>

    <div class="moreContainer">
        <div class="header">
            MEHR VO ÜS:
        </div>
        <div class="slider">
            <div class="sliderContainer">
                <draggable-slider [type]="'news'"></draggable-slider>
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
