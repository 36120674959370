import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Title, Meta } from '@angular/platform-browser';
import {gsap} from 'gsap';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  news: any = [];

  constructor(private dataService: DataService, private title: Title, private meta: Meta) 
  {
    this.getNews();
  }

  getNews = async () => {
    this.news = await this.dataService.getAllNews();
  }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Neues vo üs.");
    this.meta.addTag({ name: 'description', content: 'Was uns bewegt – und deinen Geschmack. Hier gibt’s alle News von VO ÜS.'});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Neues vo üs.' });
    this.meta.addTag({ name: 'og:description', content: 'Was uns bewegt – und deinen Geschmack. Hier gibt’s alle News von VO ÜS.'});
    window.scroll(0,0);
  }

  ngAfterViewInit(){
      gsap.from(".articleContainer", {
        x: -20,
        opacity: 0,
        stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
      });
  }

}
