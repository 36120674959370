import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-press-kit',
  templateUrl: './press-kit.component.html',
  styleUrls: ['./press-kit.component.scss']
})
export class PressKitComponent implements OnInit {

  downloads: any = [];

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    window.scroll(0,0);
    this.downloads = this.dataService.getPresskit();
  }

  downloadFile(item){
    //var blob = new Blob([item.downloadUrl], {type: 'image/png'});
    FileSaver.saveAs(item.downloadUrl);
  }

  getAllDownloads = async () => {
    this.downloads = await this.dataService.getPresskit();
  }

}
