import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { gsap } from 'gsap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  currentIndexCategory = 0;
  currentItemIndex = null;


  faqs: any = [];

  constructor(private title: Title, private meta: Meta, private dataServie: DataService) { }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » FAQs");
    this.meta.addTag({ name: 'description', content: 'Fragen, Fragen, Fragen? Antworten, Antworten, Antworten! Hier geht’s zu den FAQs rund um VO ÜS.'});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » FAQs' });
    this.meta.addTag({ name: 'og:description', content: 'Fragen, Fragen, Fragen? Antworten, Antworten, Antworten! Hier geht’s zu den FAQs rund um VO ÜS.'});
    window.scroll(0,0);
    this.faqs = this.dataServie.getFaqs();
  }

  ngAfterViewInit(){
    gsap.from(".faqsContainer", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
  }

  chooseNewCategory(index){
    this.currentIndexCategory = index;
    this.currentItemIndex = null;
  }

  chooseCategoryItem(index){
    this.currentItemIndex = index;
  }

}
