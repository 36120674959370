import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { gsap } from 'gsap';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  lat: number = 47.421190;
  lng: number = 9.670870;
  zoom: number = 15;

  contactForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';

  message: string = '';

  constructor(private formBuilder: FormBuilder, private title: Title, private meta: Meta, private db: AngularFirestore) { }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Kontakt");
    this.meta.addTag({ name: 'description', content: 'Fragen? Wünsche? Anregungen? Hier ist VO ÜS und hier sind unsere Kontaktdaten.'});
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Kontakt' });
    this.meta.addTag({ name: 'og:description', content: 'Fragen? Wünsche? Anregungen? Hier ist VO ÜS und hier sind unsere Kontaktdaten.'});
    window.scroll(0,0);
    this.contactForm = this.formBuilder.group({
      subject: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required]),
      phoneNumber: new FormControl(),
      fullName: new FormControl('',[Validators.required]),
      readAGBs: new FormControl(null,[Validators.requiredTrue]),
      message: new FormControl('',[Validators.required])
    });
  }

  ngAfterViewInit(){
    gsap.from(".formGroup", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
}

  async sendMail(){
    this.db.collection("emails").add({
      fullName: this.contactForm.value.fullName,
      email: this.contactForm.value.email,
      phoneNumber: this.contactForm.value.phoneNumber,
      message: this.contactForm.value.message,
      subject: this.contactForm.value.subject
    }).then((resp)=>{
      this.message = "Deine Nachricht wurde erfolgreich gesendet!"
      this.contactForm.reset();
    }).catch((err)=>{
      this.message = "Deine Nachricht konnte nicht versendet werden. Versuche es bitte erneut."
    })
  }

}
