import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { gsap } from 'gsap';

@Component({
  selector: 'app-press-article',
  templateUrl: './press-article.component.html',
  styleUrls: ['./press-article.component.scss']
})
export class PressArticleComponent implements OnInit {

  articleData: any;
  articleID;
  isMobile: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService)
  {
    this.activatedRoute.url.subscribe(async(data)=>{
      window.scrollTo({top:0, behavior: 'smooth'});
      var path = data[0].path;
      this.articleID = data[1].path;
      if(path == "pressArticle"){
        // this.articleData = this.dataService.getPressArticle(this.articleID);
      }
      else if(path == "newsArticle"){
        this.articleData = await this.dataService.getNewsItem(this.articleID);
      }
      else{
        //this.articleData = this.dataService.getInfo(this.articleID);
      }
    });
  }

  ngOnInit(): void {
    window.scroll(0,0);
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.isMobile = true;
    }
  }

  ngAfterViewInit(){
    gsap.from(".articleContentContainer", {
      x: -20,
      opacity: 0,
      stagger: 0.2 // 0.1 seconds between when each ".box" element starts animating
    });
}

}
