<div class="faqContainer">
    <app-header class="header" [name]="'FAQ\'S'" [type]="'zimba'"></app-header>
    <div class="instructionContainer">
        <div class="header">Was du schon immer vo üs wissen wolltest.</div>
        <!--
        <div class="content">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labo.
        </div>
        -->
    </div>

    <div class="faqsContainer">
        <div class="headerContainer">
            <div class="headerItem" *ngFor="let item of faqs; index as i" (click)="chooseNewCategory(i)" [class.active]="i == currentIndexCategory">
                {{item?.category}}
            </div>
        </div>
        <div class="contentItem" *ngFor="let item of faqs[currentIndexCategory].contentArray; index as i" (click)="chooseCategoryItem(i)" [class.firstItem]="i == 0">
            <div class="header">
                {{item?.header}}
            </div>
            <div class="content" *ngIf="currentItemIndex == i">
                {{item?.content}}
            </div>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
