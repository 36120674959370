import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-liablity-committee",
  templateUrl: "./liablity-committee.component.html",
  styleUrls: ["./liablity-committee.component.scss"],
})
export class LiablityCommitteeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scroll(0, 0);
  }
}
