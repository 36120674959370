import { Component, OnInit } from '@angular/core';
import {TimelineMax} from 'gsap';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private title: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.title.setTitle("VO ÜS » Aus Vorarlberg – nicht von irgendwo.");
    this.meta.addTag({ name: 'description', content: 'VO ÜS für dich: Limo, Säfte, Wasser, Cola und Eistee aus Vorarlberg – nicht von irgendwo. '});
    this.meta.addTag({ name: 'keywords', content: 'VO ÜS, Limo, VO ÜS Limo, Limonade aus Vorarlberg' });
    this.meta.addTag({ name: 'og:title', content: 'VO ÜS » Aus Vorarlberg – nicht von irgendwo.' });
    this.meta.addTag({ name: 'og:description', content: 'VO ÜS für dich: Limo, Säfte, Wasser, Cola und Eistee aus Vorarlberg – nicht von irgendwo. '});
    window.scroll(0,0);
  }

  ngAfterViewInit(){
    this.animateLandingPage();
  }

  animateLandingPage(){
    var animatedItem1 = document.querySelectorAll(".cloudImage");
    var animatedItem2 = document.querySelectorAll(".cloudImage2");
    var tl = new TimelineMax();
    
    tl.to(animatedItem1, 1,{opacity: 1, xPercent: 20, yPercent: 80})
    .to(animatedItem2, 1, {opacity: 1, yPercent: 50, xPercent: -5}, 0.5);

    tl.play();
  }

}
