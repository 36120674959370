import { Component, OnInit, Input, HostListener } from "@angular/core";
import Glide from "@glidejs/glide";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "draggable-slider",
  templateUrl: "./draggable-slider.component.html",
  styleUrls: ["./draggable-slider.component.scss"],
})
export class DraggableSliderComponent implements OnInit {
  @Input() type: any;

  data: any = [];
  class: any = "newsGlider";
  route: any = "newsArticle";
  activeIndex: any;
  glide: any = new Glide(".newsGlider", {
    type: "carousel",
    gap: 0,
    autoplay: 6000,
    length: 3
  });

  constructor(private dataService: DataService) {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
    } else {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  ngOnInit(): void {
    // if (this.type == "info") {
    //   //this.data = this.dataService.getInfos();
    //   this.class = "infoGlider";
    //   this.route = "infoArticle";
    // } else {
    //   this.data = this.dataService.getNews();
    //   this.class = "newsGlider";
    //   this.route = "newsArticle";
    // }
  }

  getNewsAndSetup = async () => {
    this.data = await this.dataService.getAllNews();
    setTimeout(() => {
      this.glide.mount();

      this.activeIndex = this.glide.index;
      var self = this;

      this.glide.on("run", (event) => {
        self.activeIndex = this.glide.index;
      });
    }, 2);
  }

  ngAfterViewInit(){
    this.getNewsAndSetup();
  }
}
