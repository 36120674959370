import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DataService {
  //PRODUCTS
  products = [
    {
      id: 0,
      name: "Birne-Honig VO ÜS: Enthält Spuren von Heimat.",
      soldOut: true,
      description:
        "Wenn’s um diese Limo geht, strengen sich die Bienen im Bregenzerwald aber doppelt an! Fruchtige Birne und 100% Wäldar Honig aus Sibratsgfäll – das klingt schon vor dem ersten Schluck so lecker, das kann echt nur VO ÜS sein.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Birne-Honig.png",
        },
      ],
      ingredients:
        "Wasser, 7% Mehrfruchtsaft aus Mehrfruchtsaftkonzentrat (2,8% Birne, 2,5% Apfel, 1,7% Zitrone), Zucker, Kohlensäure, 0,5% Honig, natürliches Aroma, Säuerungsmittel Zitronensäure, Antioxidationsmittel Ascorbinsäure, Stabilisatoren: Pektine, Johannisbrotkernmehl",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "119kJ / 28kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,8g",
        },
        {
          name: "-davon Zucker",
          value: "6,7g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 1,
      name: "Zitrone naturtrüb VO ÜS: Sauer macht das Ländle lustig.",
      description:
        "Wobei Zitrone naturtrüb von VO ÜS gar nicht so sauer ist, wie man denkt – sondern genau auf den Punkt so erfrischend, wie man es sich an heißen Tagen wünscht. ",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_ZitroneNT.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_ZitroneNT.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 3 % Mehrfruchtsaft aus Mehrfruchtsaftkonzentrat (1,1 % Orange, 1 % Zitrone, 0,90 % Apfel), Kohlensäure, Säuerungsmittel Zitronensäure, natürliches Zitronen-Aroma mit anderen natürlichen Aromen, Antioxidationsmittel Ascorbinsäure, Stabilisatoren: Pektine, Johannisbrotkernmehl",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "119kJ / 28kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,7g",
        },
        {
          name: "-davon Zucker",
          value: "6,7g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 2,
      name: "Zirbe Zitrone VO ÜS: Die freundlich-herbe Seite Vorarlbergs.",
      description:
        "Warum ist eigentlich das Z der letzte Buchstabe im Alphabet? Na weil die Zitrone und die Zirbe dem Durst ein Ende setzen. Und zwar ebenso gründlich wie lecker, herb und unverschämt frisch.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_ZirbeZitrone.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 3 % Orangensaft aus Orangensaftkonzentrat, 2 % Zitronensaft aus Zitronensaftkonzentrat, Kohlensäure, Säuerungsmittel Zitronensäure, Zitronenextrakt, Stabilisatoren: Pektine, Johannisbrotkernmehl; färbendes Karottenkonzentrat, Zirbenkieferöl",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "117kJ / 28kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,7g",
        },
        {
          name: "-davon Zucker",
          value: "6,7g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 3,
      name: "Holunder VO ÜS: Für alle guten Geister vo do.",
      description:
        "Die Sage erzählt, dass im Holunderbaum der gute Geist eines jeden Hauses lebt. Ob man das nun glaubt oder nicht – nach dem ersten säuerlich-süßen Schluck weiß man auf jeden Fall, dass Holunder VO ÜS richtig gut schmeckt. Geister hin oder her.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Holunder.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Holunder.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, Holunderblüten-Extrakt (0,85 %), Kohlensäure, Säuerungsmittel Zitronensäure, Aroma, Farbstoff Ammonsulfit-Zuckerkulör",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "117kJ / 28kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,7g",
        },
        {
          name: "-davon Zucker",
          value: "6,7g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 4,
      name: "Himbeer-Zitrone Schiwasser VO ÜS: Auf unseren Pisten daheim.",
      description:
        "Hoch mit dem Lift. Schussfahrt ins Tal. Einmal, zweimal, dreimal. Da freut man sich schon auf den nächsten Durst. Weil man ihn mit VO ÜS löschen kann: Die unwiderstehliche Kombination aus Himbeere und Zitrone rauscht glatt durch jede Kehle.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_HimbeerZitrone.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_HimbeerZitrone.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 5 % Mehrfruchtsaft aus Mehrfruchtsaftkonzentrat (2 % Himbeer, 2 % Apfel, 1 % Zitrone), Kohlensäure, natürliches Zitronen-Aroma mit anderen natürlichen Aromen, Säuerungsmittel Zitronensäure, färbende Konzentrate: Karotte, Granatapfel",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "119kJ / 28kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,7g",
        },
        {
          name: "-davon Zucker",
          value: "6,7g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 5,
      name: "Vogelbeer-Kirsch VO ÜS: Echte Freundschaft aus dem Ländle.",
      description:
        "Wer hätte das gedacht? Die Kirsche hat eine neue beste Freundin: die Vogelbeere. Und was treiben die beiden gemeinsam den ganzen Tag? Na klar: Sie haben jede Menge Spaß am Geschmack und warten darauf, restlos ausgetrunken zu werden.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_VogelbeerKirsch.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 5 % Mehrfruchtsaft aus Mehrfruchtkonzentrat (3 % Kirsche, 1 % Zitrone, 1 % Apfel), Kohlensäure, natürliches Aroma, Säuerungsmittel Zitronensäure, färbendes Karottenkonzentrat",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "117kJ / 27kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,5g",
        },
        {
          name: "-davon Zucker",
          value: "6,5g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 6,
      name: "Macht das Ländle sonnig: Orangenlimo VO ÜS.",
      description:
        "Eigentlich ist die Frage ziemlich überflüssig, aber wir stellen sie hier trotzdem: Gibt es etwas Schöneres, als Orangenlimonade? Natürlich nicht. Erst recht nicht, wenn sie VO ÜS kommt. Gut, dass wir das geklärt haben.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Orangenlimo.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Orangenlimo.png",
        },
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Orangenlimo.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 10 % Mehrfruchtsaft aus Mehrfruchtsaftkonzentrat (5 % Mandarine, 4,5% Orange, 0,5 % Zitrone), Kohlensäure, Orangenextrakt, Säuerungsmittel Zitronensäure, natürliches Orangen-Aroma mit anderen natürlichen Aromen, Stabilisatoren: Pektine, Johannisbrotkernmehl; Antioxidationsmittel Ascorbinsäure, Farbstoff Carotin",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "143kJ / 34kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "8,2g",
        },
        {
          name: "-davon Zucker",
          value: "8,0g",
        },
        {
          name: "Eiweiß",
          value: "0,1g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 7,
      name: "Endlich eine Cola VO ÜS!",
      description:
        "Eine Cola ist eine Cola ist eine Cola? Von wegen! Eine Cola aus Vorarlberg ist eine Cola VO ÜS. Von hier, nicht von irgendwo. Gemacht von uns, nicht von irgendwem. Und ganz speziell für alle hier. Mit echtem Kolanuss-Extrakt, was sonst?",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Cola.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Cola.png",
        },
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Cola.png",
        },
      ],
      ingredients:
        "Wasser, Farbstoff, Ammonsulfit-Zuckerkulör, Invertzuckersirup, Säuerungsmittel Phosphorsäure, natürliches Aroma, Kohlensäure, Aroma: Koffein",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "182kJ / 43kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "11g",
        },
        {
          name: "-davon Zucker",
          value: "11g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 8,
      name: "Cola ohne Zucker VO ÜS: Wie Cola, nur leichter.",
      image: "../../../assets/imgs/products/Cola_0,5.png",
      description:
        "Cola ist klasse – wenn nur der Zucker nicht wäre! Das denken Viele. Und das wissen wir. Darum gibt es Cola VO ÜS auch ohne Zucker. Aber mit genauso viel gutem Geschmack, wie ihn eine gute Cola braucht.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Cola_ohne_Zucker.png",
        },
      ],
      ingredients:
        "Wasser, Kohlensäure, Farbstoff E150d, Phosphorsäure, Säureregulator Trinatriumcitrat, Süßungsmittel Sucralose, natürliches Aroma, Säurungsmittel Phosphorsäure, Aroma: Koffein",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "1kJ / 0kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "0g",
        },
        {
          name: "-davon Zucker",
          value: "0g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 9,
      name: "Cola Mix VO ÜS: Wir gehören zusammen!",
      image: "../../../assets/imgs/products/Cola_1.png",
      description:
        "So ein Tag ohne Mix – is nix. Wenn kräftige Cola auf fruchtige Orange trifft, möchte man mittendrin sein. Aber hierzulande hat man es ja nicht weit: dieser Cola Mix kommt schließlich von hier, aus Vorarlberg und VO ÜS.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_ColaMix.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_ColaMix.png",
        },
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Colamix.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, 3 % Orangensaft aus Orangensaftkonzentrat, Kohlensäure, Säuerungsmittel: Zitronensäure, Phosphorsäure; Farbstoff Ammonsulfit-Zuckerkulör, Stabilisatoren: Pektine, Johannisbrotkernmehl; natürliches Orangen-Aroma mit anderen natürlichen Aromen, Aroma: Koffein",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "182kJ / 43kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "10,6g",
        },
        {
          name: "-davon Zucker",
          value: "10,5g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 10,
      name: "Mal eben frisch machen: Zitronenlimo VO ÜS.",
      description:
        "Gleich nach dem ersten Schluck fragt man sich: wer hat hier eigentlich die Sonne in die Limo getan? Wir waren das! Warum? Weil wir es können! Das ist schließlich eine superfrische, sauersüße Limo VO ÜS.",
      category: "Limo",
      sizes: [
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Zitronenlimo.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, Kohlendioxid, Säurungsmittel, Zitronensäure, Farbstoff Ammoniumsulfit-Zuckerkulör, natürliche Kräuterextrate.",
      nutritionalValues: [
        {
          name: "Energie",
          value: "185 kH/43 kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "10,5g",
        },
        {
          name: "-davon Zucker",
          value: "10,5g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0,01g",
        },
      ],
    },
    {
      id: 11,
      name: "Kräuter-Brennnessel VO ÜS: Frisch aus dem Gebüsch.",
      description:
        "Hohe Berge, saftige Wiesen, klares Wasser. So kennt man Vorarlberg. Und so trinkt man es auch: Mit Kräutern und Brennnessel von hier und VO ÜS. Herzlich willkommen daheim.",
      category: "Limo",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Kra%CC%88uter+Brennnessel.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Kra%CC%88uterBrennnessel.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, Kohlensäure, natürliches Kräuter-Zitronenaroma, Säuerungsmittel Milchsäure, Karamellzuckersirup, Brennnesselextrakt, Melissenextrakt",
      nutritionalValues: [
        {
          name: "Energie",
          value: "120 kJ/28 kcal",
        },
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,8g",
        },
        {
          name: "-davon Zucker",
          value: "6,8g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 12,
      name: "ISO VO ÜS: Vorarlberg im Blut",
      description:
        "Eben war der Durst noch da. Und ZACK! war er plötzlich weg. Dafür sind alle Lebensgeister schon wieder da. Wer sich so fühlt, wurde gerade isotonisch wertvoll und durststillend lecker von VO ÜS erfrischt!",
      category: "Limo",
      sizes: [
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Iso_9-03-21.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, Kohlendioxid, Säurungsmittel, Zitronensäure, Farbstoff Ammoniumsulfit-Zuckerkulör, natürliche Kräuterextrate.",
      nutritionalValues: [
        {
          name: "Energie",
          value: "78kJ/ 19kcal",
        },
        {
          name: "Fett",
          value: "< 0,1g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "< 0,02g",
        },
        {
          name: "Kohlenhydrate",
          value: "4,3g",
        },
        {
          name: "-davon Zucker",
          value: "4,3g",
        },
        {
          name: "Eiweiß",
          value: "< 0,1g",
        },
        {
          name: "Salz",
          value: "< 0,1g",
        },
      ],
    },
    {
      id: 13,
      name: "Gespritzter Apfel VO ÜS: Pflückt locker jeden Durst.",
      description:
        "Echte Vorarlbergerinnen und Vorarlberger löschen ihren Durst direkt vom Baum. Natürlich? Logo! Prickelnd? Na sicher. Lieblingsäpfel kommen stets VO ÜS.",
      category: "Saft",
      sizes: [
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Apfelsaft_gespritzt.png",
        },
      ],
      ingredients: "Apfelsaft aus Apfelsaftkonzentrat, Wasser, Kohlensäure",
      nutritionalValues: [
        {
          name: "Fett",
          value: "0,1g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0,1g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,4g",
        },
        {
          name: "-davon Zucker",
          value: "6,1g",
        },
        {
          name: "Eiweiß",
          value: "0,1g",
        },
        {
          name: "Salz",
          value: "0g",
        },
      ],
    },
    {
      id: 14,
      name: "Apfelsaft VO ÜS: Bäume von hier, noch mehr SAFT für alle.",
      description:
        "Na klar gibt’s Apfelsaft VO ÜS auch still – ohne „Blöterle”. Er stammt ausschließlich von Äpfeln aus der Bodenseeregion, die wir direkt nach der Pflückung schonend verarbeitet haben. Hätte man sich denken können, aber wir erwähnen es trotzdem voller Stolz nochmal.",
      category: "Saft",
      sizes: [
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/Pfanner_1%2C0_Apfelsaft+klar.png",
        },
      ],
      ingredients:
        "100% Apfelsaft aus Apfelsaftkonzentrat. Vor dem Öffnen gut schütteln. Nach dem Öffnen gekühlt aufbewahren und innerhalb von 3 Tagen aufbrauchen.",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "189kJ / 45kcal",
        },
        {
          name: "Fett",
          value: "0,5g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0,1g",
        },
        {
          name: "Kohlenhydrate",
          value: "10g",
        },
        {
          name: "-davon Zucker",
          value: "10g",
        },
        {
          name: "Eiweiß",
          value: "0,5g",
        },
        {
          name: "Salz",
          value: "0,01g",
        },
      ],
    },
    {
      id: 15,
      name: "Orangennektar VO ÜS: Sommer VO ÜS!",
      description:
        "Schmeckt so, duftet so. Aber kann das wirklich sein? Ja, kann es: Wir haben den Sommer in Flaschen gefüllt. Wie wir das gemacht haben? Wir sind VO ÜS – wir können alles!",
      category: "Saft",
      sizes: [
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/Pfanner_1%2C0_Orangensaft.png",
        },
      ],
      ingredients: "Orangensaft aus Orangensaftkonzentrat, Wasser, Zucker",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "185kJ / 43kcal",
        },
        {
          name: "Fett",
          value: "0,5g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0,1g",
        },
        {
          name: "Kohlenhydrate",
          value: "9g",
        },
        {
          name: "-davon Zucker",
          value: "9g",
        },
        {
          name: "Eiweiß",
          value: "0,5g",
        },
        {
          name: "Salz",
          value: "0,01g",
        },
      ],
    },
    {
      id: 16,
      name: "Gespritzte Johannisbeere VO ÜS: Bitte lächeln!",
      description:
        "So spritzig ist es der Johannisbeere noch nie zumute gewesen. Wenn sie fruchtig auf das frische Vorarlberger Wasser VO ÜS trifft, sieht die Welt ganz anders aus. Die Johannisbeere lächelt – in der 0,5l-Flasche steht ihr eine leckere Zukunft bevor.",
      category: "Saft",
      sizes: [
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Johannisbeere_gespritzt.png",
        },
      ],
      ingredients:
        "Wasser, schwarzer Johannisbeersaft aus Konzentrat, Zucker, Säuerungsmittel Zitronensäure, Kohlensäure, Antioxidationsmittel L-Ascorbinsäure, pasteurisiert",
      nutritionalValues: [
        {
          name: "Fett",
          value: "0,5g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "6,5g",
        },
        {
          name: "Kohlenhydrate",
          value: "6,5g",
        },
        {
          name: "-davon Zucker",
          value: "6,5g",
        },
        {
          name: "Eiweiß",
          value: "0,5g",
        },
        {
          name: "Salz",
          value: "0,01g",
        },
      ],
    },
    {
      id: 17,
      name: "Johannisbeernektar VO ÜS: Ein neuer Saftausdruck.",
      description:
        "Wie viele Johannisbeeren muss man eigentlich sammeln, um SO lecker zu sein? Es sind SEHR viele. Und für wen haben wir das getan? Für alle Saftfreunde im Ländle. Weil wir euch mögen. So sind wir eben.",
      category: "Saft",
      sizes: [
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/Pfanner_1%2C0_Johannisbeersaft.png",
        },
      ],
      ingredients:
        "Wasser, Schwarzer Johannisbeersaft aus schwarzem Johannisbeersaftkonzentrat, Zucker, Säuerungsmittel Citronensäure",
      nutritionalValues: [
        {
          name: "Fett",
          value: "<0,5g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "<0,1g",
        },
        {
          name: "Kohlenhydrate",
          value: "11g",
        },
        {
          name: "-davon Zucker",
          value: "11g",
        },
        {
          name: "Eiweiß",
          value: "<0,5g",
        },
        {
          name: "Salz",
          value: "<0,01g",
        },
      ],
    },
    {
      id: 18,
      name: "Pfirsich-Eistee VO ÜS: Passt!",
      description:
        "Was so nach Urlaub schmeckt, kann ja nur aus Vorarlberg kommen: Wenn der fruchtige Pfirsich auf erfrischenden Eistee trifft, wird’s so lecker, dass es einfach VO ÜS sein muss. Ist es auch!",
      category: "Eistee",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Eistee.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Eistee.png",
        },
      ],
      ingredients:
        "Wasser, Zucker, Aroma, Säuerungsmittel Citronensäure, Schwarztee-Extrakt, Säureregulator Natriumcitrate, Antioxidationsmittel Ascorbinsäure, Konservierungsstoffe: E202, E211",
      nutritionalValues: [
        {
          name: "Fett",
          value: "0g",
        },
        {
          name: "-davon gesättigte Fettsäuren",
          value: "0g",
        },
        {
          name: "Kohlenhydrate",
          value: "10,5g",
        },
        {
          name: "-davon Zucker",
          value: "10,5g",
        },
        {
          name: "Eiweiß",
          value: "0g",
        },
        {
          name: "Salz",
          value: "0,01g",
        },
      ],
    },
    {
      id: 19,
      name: "Soda VO ÜS: Es kribbelt in Vorarlberg.",
      description:
        "So prickelnd kann das Ländle sein: Soda VO ÜS aus frischem Vorarlberger Wasser ist die richtige Begleitung für jeden Drink. Tritt aber auch solo auf und weiß selbst Feinschmeckerzungen zu überzeugen.",
      category: "Wasser",
      sizes: [
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Soda.png",
        },
      ],
      ingredients: "Wasser, Kohlensäure",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "0kJ / 0kcal",
        },
      ],
    },
    {
      id: 20,
      name: "Tafelwasser VO ÜS: Na klar.",
      description:
        "Auf den feinen Tischen Vorarlbergs zuhause: Tafelwasser VO ÜS. Keineswegs farblos, sondern mit allem, was das Ländle bietet: Frische und Reinheit, wie es sie nur VO ÜS gibt.",
      category: "Wasser",
      sizes: [
        {
          size: "0,33l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C33_Tafelwasser.png",
        },
        {
          size: "0,5l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_0%2C5_Tafelwasser.png",
        },
        {
          size: "1l",
          image:
            "https://voues.s3.eu-central-1.amazonaws.com/product-images/VoUes_1%2C0_Tafelwasser.png",
        },
      ],
      ingredients: "Wasser, Kohlensäure",
      nutritionalValues: [
        {
          name: "Brennwert",
          value: "0kJ / 0kcal",
        },
      ],
    },
  ];

  //FAQ's
  faqs = [
    {
      category: "Allgemein",
      contentArray: [
        {
          header: "Warum habt ihr eine eigene Getränkemarke entwickelt?",
          content:
            "Regional, statt global und von zwei heimischen Traditionsbrauereien, statt von einem riesigen, internationalen Getränkekonzern – das fanden wir ziemlich sinnvoll. ",
        },
        {
          header: "Was habt ihr für die Zukunft noch geplant?",
          content:
            "Weitere fruchtig-frische regionale Sorten von VO ÜS entwickeln. Immer über den Tellerrand schauen. Und noch eine Menge mehr!",
        },
        {
          header: "Warum soll ich VO ÜS kaufen?",
          content:
            "In erster Linie: weil’s schmeckt! Und weil es ein Stück Vorarlberg ist. 😊",
        },
        {
          header:
            "Was habe ich als Gastronom davon, VO ÜS auf die Getränkekarte zu nehmen?",
          content:
            "Mit VO ÜS erweitert man das eigene Sortiment um eine vielfältige Marke, die den Zeitgeist trifft und einen spürbaren Bezug zu Vorarlberg besitzt. VO ÜS trinkt man nicht nur – über VO ÜS spricht man auch.",
        },
        {
          header: "Was sind das für Berge auf den Etiketten?",
          content:
            "Die markantesten Berge aus Vorarlberg und Quell unserer Grundzutat – des Vorarlberger Vorarlberger Wasser in VO ÜS.",
        },
        {
          header: "Kann man Werbeartikel von euch irgendwo käuflich erwerben?",
          content:
            "Ja, im Mohren-Lädele und im Shop der Brauerei Frastanzer. Schau mal rein!",
        },
      ],
    },
    {
      category: "Unternehmen",
      contentArray: [
        {
          header:
            "Wer kam auf die Idee, eine eigene Getränkemarke zu entwickeln?",
          content:
            "Wir haben eine Vorarlberger Tugend genutzt: „Machen was Sinn macht und das mit vereinten Kräften!“ Darum haben sich Mohren und Frastanzer dazu entschlossen, eine gemeinsame Gesellschaft zu gründen und zusammen Getränke VO ÜS zu produzieren.",
        },
        {
          header: "Wer steht hinter VO ÜS?",
          content: "Die Nummer 1 & 3 des Vorarlberger Biermarktes. 😊",
        },
        {
          header: "Gehört VO ÜS zu einem großen Getränkekonzern?",
          content:
            "Auf keinen Fall – wir sind das gallische Dorf der Getränkemarken. VO ÜS ist zu 100% in Vorarlberger Hand! Und so bleibt das auch.",
        },
        {
          header: "Wie hebt ihr euch qualitativ von anderen Herstellern ab?",
          content:
            "VO ÜS – das sind regional produzierte Getränke und somit kurze Wege, mit nur so viel Zucker wie nötig und mit frisch-fruchtigen Sorten, die speziell auf den Vorarlberger Geschmack zugeschnitten sind.",
        },
        {
          header:
            "Habt ihr eine eigene Abfüllanlage, die man besichtigen kann?",
          content:
            "Ja, wir haben eine eigene Abfüllanlage, die du aus hygienetechnischen Gründen aber leider nicht besichtigen kannst. Soll ja alles frisch, fruchtig und lecker sein. Sorry! 😉",
        },
      ],
    },
    {
      category: "Produkt",
      contentArray: [
        {
          header: "Wo kann man VO ÜS kaufen?",
          content:
            "Bei Mohren und Frastanzer, in der ausgesuchten Vorarlberger Gastronomie und im Vorarlberger Lebensmitteleinzelhandel.",
        },
        {
          header: "Kann man VO ÜS auch mixen?",
          content:
            "Aber klar! Wir setzen deinem Geschmack und deiner Phantasie doch keine Grenzen!",
        },
        {
          header: "Ist VO ÜS vegan?",
          content:
            "Das Getränk ja – für die Etiketten verwenden wir jedoch einen Kaseinkleber.",
        },
        {
          header: "Welche Inhaltsstoffe werden verwendet?",
          content:
            "Viel frisches Vorarlberger Vorarlberger Wasser. Und jede Menge Geheimnisse!",
        },
        {
          header: "Werden natürliche Zutaten verwendet?",
          content: "Es werden nur natürliche Aromen verwendet, ist doch klar.",
        },
        {
          header: "Woher kommen die Zutaten?",
          content:
            "Aus der ganzen Welt! Aber wir wählen unter strengen Auflagen aus, was in VO ÜS landet und was nicht.  ",
        },
        {
          header: "Woher kommt das Wasser in den Getränken?",
          content: "Von hier – aus deinen Vorarlberger Bergen.",
        },
        {
          header: "Gibt es auch Tonic?",
          content:
            "Noch nicht – aber du bringst uns da gerade auf eine Idee...",
        },
        {
          header: "Wie lange ist VO ÜS haltbar?",
          content: "9 Monate.",
        },
        {
          header: "Wo wird VO ÜS hergestellt?",
          content: "Hier in Vorarlberg. Nicht irgendwo.",
        },
        {
          header: "Sind die Produkte bio?",
          content:
            "Aktuell nur eine Sorte – aber wir denken bio und entwickeln uns immer weiter.",
        },
        {
          header: "Wieviel Zucker enthält eine Flasche VO ÜS?",
          content: "Von 6,5 – 11 g. Immer nur so viel, wie nötig. ",
        },
        {
          header: "Wo kann ich die leeren Flaschen zurückgeben?",
          content: "Dort, wo du sie gekauft hast.",
        },
        {
          header: "Welche Produkte gibt es von VO ÜS?",
          content:
            "Echt viele! Du findest alle Sorten VO ÜS auf dieser Website unter „Produkte“.",
        },
        {
          header: "Welche Süßungsmittel werden wo eingesetzt, wenn ja, welche?",
          content: "Das Süßungsmittel bei Cola ohne ist Sucralose.",
        },
        {
          header:
            "Warum steht auf dem Etikett der bio Kräuter-Brennnessel Limonade „EU-/Nicht-EU-Landwirtschaft?“",
          content:
            "Unsere Bio-Produzenten für die Kräuter-Brennnessel-Limonade beziehen ihre Grundstoffe alle aus der EU. Falls der aber einmal nicht verfügbar ist, möchten sie sich die Option offen halten, auch Grundstoffe die nicht aus der EU kommen verarbeiten zu können. Deshalb steht auf der VO ÜS Bio Kräuter-Brennnessel „EU-/Nicht-EU-Landwirtschaft“. Aber wir arbeiten daran, dass in Zukunft alle Grundstoffe GARANTIERT aus der EU kommen.",
        },
      ],
    },
    {
      category: "Verpackung",
      contentArray: [
        {
          header: "Warum habt ihr euch für Glas entschieden?",
          content:
            "Logisch: Nachhaltigkeit spielt für uns eine große Rolle. Weil wir in Vorarlberg kurze Wege haben, macht Mehrweg Sinn.",
        },
        {
          header: "Ist die Flasche eine Pfandflasche?",
          content: "tbd",
        },
        {
          header: "Wo kann ich sie zurückgeben?",
          content: "Dort, wo du sie gekauft hast.",
        },
      ],
    },
    {
      category: "Nachhaltigkeit/Umwelt",
      contentArray: [
        {
          header: "Was macht ihr für die Umwelt?",
          content:
            "Kurze Wege! Weniger CO2! Mehrweg! Weniger Müll! Wir sind VO ÜS, wie achten auf die Natur vo üs.",
        },
      ],
    },
  ];

  //locations
  locations = [
    {
      id: 1,
      name: "Mohrenbrauerei Vertriebs KG",
      street: "Millennium Park 10",
      city: "A-6890 Lustenau",
      phone: "+43-5572-3777-0",
      email: "info@mohrenbrauerei.at",
      website: "www.mohrenbrauerei.at",
      cords: {
        lat: 47.42119,
        lng: 9.67087,
      },
    },
    {
      id: 2,
      name: "Brauerei Frastanz eGen",
      street: "Bahnhofstr. 22",
      city: "A-6820 Frastanz",
      phone: "+43-5522-51701-0",
      email: "bier@frastanzer.at",
      website: "www.frastanzer.at",
      cords: {
        lat: 47.21866,
        lng: 9.63698,
      },
    },
  ];

  //downloads
  downloads = [
    {
      name: "VOÜS Logo zweiteilig mit Claim, blau",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/vou%CC%88s_Logo_Claim-2+zeilig_4c.png",
      donwloadName: "vouesLogo_zweiteilig_blau.png",
      type: "image",
      category: "Logo",
    },
    {
      name: "VOÜS Logo zweiteilig mit Claim, weiß",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/vou%CC%88s_Logo_Claim-2+zeilig_wei%C3%9F.png",
      donwloadName: "vouesLogo_zweiteilig_weiß.png",
      type: "image",
      category: "Logo",
    },
    {
      name: "VOÜS Logo mit Claim, blau",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/vou%CC%88s_Logo_Claim-lang_4c.png",
      donwloadName: "vouesLogo_blau.png",
      type: "image",
      category: "Logo",
    },
    {
      name: "VOÜS Logo mit Claim, weiß",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/vou%CC%88s_Logo_Claim-lang_wei%C3%9F.png",
      donwloadName: "vouesLogo_weiß.png",
      type: "image",
      category: "Logo",
    },
  ];

  pressKit = [
    {
      name: "Pressefoto VOÜS",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/Pressefoto_1.jpeg",
      donwloadName: "Pressefoto.png",
      type: "image",
      category: "Pressefoto",
    },
    {
      name: "Pressefoto VOÜS",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/Pressefoto_2.jpeg",
      donwloadName: "Pressefoto.png",
      type: "image",
      category: "Pressefoto",
    },
    {
      name: "Pressefoto VOÜS",
      downloadUrl:
        "https://voues.s3.eu-central-1.amazonaws.com/downloads/Pressefoto_3.jpeg",
      donwloadName: "Pressefoto.png",
      type: "image",
      category: "Pressefoto",
    },
    {
      name: "Presseinformation VOÜS",
      downloadUrl:
        "https://firebasestorage.googleapis.com/v0/b/voues-270518.appspot.com/o/PresseKit%2FPresseinformation.pdf?alt=media&token=b17301cc-1040-4be0-a12f-1139bc72e2fd",
      donwloadName: "Presseinformation.pdf",
      type: "pdf",
      category: "Presseinformation",
    },
  ];

  constructor(private firestore: AngularFirestore) {}

  getProducts() {
    return this.products;
  }

  getProductsOfCategory(category) {
    return this.products.filter((item) => item.category == category);
  }

  getProduct(id) {
    return this.products.find((item) => item.id == id);
  }

  getFaqs() {
    return this.faqs;
  }

  getAllNews() {
    return new Promise((resolve, reject) => {
      try {
        const allNews = this.firestore
          .collection("news", (ref) =>
            ref.where("status", "==", "1").orderBy("releaseDate", "desc")
          )
          .snapshotChanges()
          .pipe(
            map((news) =>
              news.map((a) => {
                a.payload.doc.data();
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...(data as {}) };
              })
            )
          );

        let allItems: any = [];

        allNews.subscribe((item) => {
          item.forEach((i) => {
            allItems.push(i);
          });
          resolve(allItems);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  getNewsItem = async (id) => {
    return new Promise((resolve, reject) => {
      try {
        const newsItem = this.firestore.collection("news").doc(id).get();
        newsItem.subscribe((data) => {
          resolve(data.data());
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  getLocations() {
    return this.locations;
  }

  getDownloads() {
    return this.downloads;
  }

  getDownloadsByCategory(category) {
    return this.downloads.filter((item) => item.category == category);
  }

  getPresskit = async () => {
    return new Promise((resolve, reject) => {
      try {
        const allNews = this.firestore
          .collection("press", (ref) => ref.where("status", "==", "1"))
          .snapshotChanges()
          .pipe(
            map((news) =>
              news.map((a) => {
                a.payload.doc.data();
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return { id, ...(data as {}) };
              })
            )
          );

        let allItems: any = [];

        allNews.subscribe((item) => {
          item.forEach((i) => {
            allItems.push(i);
          });
          resolve(allItems);
        });
      } catch (err) {
        reject(err);
      }
    });
  };
}
