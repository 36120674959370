<div class="pressContainer">
    <app-header class="header" [name]="'NEWS VO ÜS'" [type]="'newsHeader'"></app-header>

    <div class="pressInstructionContainer">
        <div class="introText">Was sollen wir vo üs erzählen?</div>
        <div class="introContent">Vo üs gibt es immer wieder jede Menge Neues.</div>
    </div>

    <div class="pressItemsContainer">
        <div class="articleContainer" *ngFor="let item of news">
            <article-item [name]="item.name" [image]="item.image" [route]="'newsArticle'" [id]="item.id"></article-item>
        </div>
    </div>
</div>

<!--FOOTER-->
<app-footer></app-footer>
